import { db } from "./initDB";
export const Result = {
    createResult: async function (key, result) {
        try {
            // await db.open();
            const exists = await db.results.where('key').equals(key).count();

            if(exists) {
                 await db.results.where('key').equals(key).modify({result})
            }
             await db.results.add({key, result})
            //  await db.close()
        } catch (err) {
            console.error('Error creating result:', err);
            throw err;
        }
    },

    getDataByPartialKey: async function(keyword) {
        return new Promise(async resolve => {
            try {
                const results = await db.results.filter(record =>
                    record.key.includes(keyword)
                ).toArray();
                resolve(results);
            } catch (err) {
                console.error(err);
            }
        });
    },

    getResultByKey: async function(key) {
        return new Promise(async resolve => {
            try {
                // await db.open();
                const res = await db.results.where('key').equals(key).last();
                await resolve(res);
                // await db.close();
            } catch(err) {
                console.error(err);
            }
        })
    },
    getResult: async function(id) {
        return new Promise(async resolve => {
            try {
                // await db.open();
                const res = await db.results.get(id);
                await resolve(res)
                // await db.close()
            } catch (error) {
                console.error(error);
            }
        })
    },
    getResults: async function() {
        try {
            const results = {}
            // await db.open();
            await db.results.each(result => {
                results[result.key] = result.result
            });
            return results
        } catch(err) {
            console.error(err);
        }
    },
    updateResult: async function(key, result) {
        try {
            // await db.open();
            await db.results.where('key').equals(key).modify({result})
            // await db.close()
        } catch(err) {
            console.error(err);
        }
    },
    deleteResult: async function(key) {
        try {
            // await db.open();
            await db.results.where('key').equals(key).delete()
            // await db.close()
        } catch(err) {
            console.error(err);
        }
    }
}