import axios from "@/configs/axios";
import {Result} from '@/models/result'

const state = {
    telegramComment: {},
    telegramGroupComments: {},
    historyParams: {},
    meta: {},
    metaComment: {},
    recordId: {},
}
const getters = {
    telegramComment: state => state.telegramComment,
    telegramGroupComments: state => state.telegramGroupComments,
    historyParams: state => state.historyParams,
    meta: state => state.meta,
    metaComment: state => state.metaComment,
    recordId: state => state.recordId,
}

const actions = {
    async getTelegramComments({commit, dispatch}, data) {
        let result = await axios({url: 'content_search/preview', data: data,  method: 'POST'});

        if (result.status === 200) {
            commit('setTelegramComments', result.data.results);
            commit('setHistoryParams', data);
            return result.data.results;
        }

        return [];
    },

    getTelegramGroupComments({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            axios({url: 'content_search', data: data,  method: 'POST'})
                .then((resp) => {
                    commit('setTelegramGroupComments', resp.data.results)
                    resolve(resp.data.results);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async getLocalPreviewTelegramGroupComments({commit, dispatch}, childKey) {
        let dbResponse = await Result.getResultByKey(childKey);

        if (typeof dbResponse !== 'undefined') {
            commit('setTelegramComments', dbResponse.result.results);
            commit('setHistoryParams', dbResponse.result.meta.payload.requestBody);
            return dbResponse.result;
        }

        commit('setClearTelegramComments');
        return {};
    },

    async getLocalTelegramGroupComments({commit, dispatch}, key) {
        let dbResponse = await Result.getResultByKey(key);

        if (typeof dbResponse !== 'undefined') {
            commit('setTelegramGroupComments', dbResponse.result.results);
            commit('setMeta', dbResponse.result.meta);
            commit('setMetaComment', dbResponse.result.meta);
            return dbResponse.result;
        }

        commit('setClearTelegramGroupComments');
        return {};
    },

    getTelegramGroupCommentsPaginate({commit, dispatch}, data) {
        return new Promise((resolve, reject) => {
            axios({url: `content_search/request/${data.query.request_id}/source/${data.query.source_id}`, data: data.body,  method: 'POST'})
                .then((resp) => {
                    resolve(resp.data);
                })
                .catch(err => {
                    reject(err)
                })
        })
    },

    async removeChildMainSearch({commit, dispatch}, key) {
        await Result.deleteResult(key);
    },
}

const mutations = {
    setHistoryParams(state, historyParams) {
        state.historyParams = historyParams;
    },

    setRecordId(state, id) {
      state.recordId = id;
    },

    setMeta(state, meta) {
        state.meta = meta;
    },

    setMetaComment(state, meta) {
        state.metaComment = meta;
    },

    setTelegramComments(state, payload = []) {
        state.telegramComment = payload;
    },

    setClearTelegramComments(state) {
        state.telegramComment = {};
    },

    setClearTelegramGroupComments(state) {
        state.telegramGroupComments = {};
    },

    setTelegramGroupComments(state, payload = []) {
        state.telegramGroupComments = payload;
    },

    setLocalTelegramComments(state, payload) {
        state.telegramGroupComments = payload;
    },

    setTelegramGroupCommentsPaginate(state, payload) {
        state.telegramGroupComments.forEach(comment => Object.assign(comment, {
            search_results: payload.search_results,
            relative_results: payload.relative_results,
            offset: payload.offset
        }));
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}