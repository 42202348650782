<template>
  <b-modal
      :id="`bv-modal-${modalId}`"
      size="xl"
      title="Third Modal"
      hide-footer
      @show="openModal"
      scrollable
  >
    <template #modal-title>
      <span>{{ modalTitle?.toLowerCase().split(/\s+/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}: {{ data.author.value }}</span>
    </template>

    <template #modal-header-close>
      <div class="bg-dark position-absolute end-0 top-0 m-10 p-l-10 p-r-10 rounded">
        <b-icon variant="white" icon="arrow-left-short" font-scale="3" aria-hidden="true" />
      </div>
    </template>

    <div class="d-lg-flex m-t-20 main-comment align-items-stretch">
      <group-tabs
          :groups="groups"
          :is-blocked="isLoaded"
          @get-data="getComments"
          :local-exist-data="localDataStatus"
          :active-group-id="activeTab"
      />

      <comment-list
          :structureComments="structureComments"
          :isLoaded="isLoaded"
          :active-group-id="activeTab"
          :author-id="data?.author_id"
          :group="currentGroup"
          @next-page="handleNextPage"
      />
    </div>
  </b-modal>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ImageProfile from "@/components/ImageProfile.vue";
import CommentTree from "@/components/search/comments/CommentTree.vue";
import CommentSearchPagination from "@/components/search/CommentSearchPagination.vue";
import {Result} from '@/models/result'
import dbKeyTelegramMonitor from "@/mixins/telegramMonitor";
import CommentList from "@/components/search/comments/components/CommentList.vue";
import GroupTabs from "@/components/search/comments/components/GroupTabs.vue";
import {nextPage} from "@/mixins/comments/paginate";
import {buildTreeComments} from "@/mixins/comments/handler";
import {Sources} from "@/enums/sources";

export default {
  name: "commentMainSearch",
  components: {GroupTabs, CommentTree, ImageProfile, CommentSearchPagination, CommentList},

  mixins: [dbKeyTelegramMonitor],

  props: ['data', 'groups', 'modalId', 'modalTitle'],

  data() {
    return {
      activeTab: null,
      isLoaded: true,
      structureComments: [],
      localDataStatus: [],
      matches_count: 0,
      offset: 0,
      request_id: 0,
      source_id: 0,
      currentPage: 0,
      currentGroup: {},
    }
  },

  computed: {
    ...mapGetters('search_comment', ['telegramGroupComments', 'telegramComment', 'meta']),
  },

  methods: {
    ...mapMutations('search_comment', ['setLocalTelegramComments']),

    async handleNextPage(currentPage) {
      await nextPage.call(this, currentPage, this.data);
      buildTreeComments.call(this);
    },

    async checkLocalData(groupId, data) {
      data['group_id'] = [groupId];

      let key = this.generatePreviewDbKeyWithParent(data, 'full');
      let res = await Result.getResultByKey(key);

      this.updateOrAddGroupStatus(groupId, typeof res !== 'undefined');
    },

    async updateOrAddGroupStatus(id, newStatus = null) {
      if (newStatus === null) {
        this.meta.payload.requestBody.group_id = [id];
        let key = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);

        let dbResults = await Result.getResultByKey(key);
        newStatus = typeof dbResults !== 'undefined'
      }

      const index = this.localDataStatus.findIndex(item => item.groupId === id);

      if (index !== -1) {
        this.localDataStatus[index].status = newStatus;
      } else {
        this.localDataStatus.push({ groupId: id, status: newStatus});
      }
    },

    openModal() {
      this.$store.dispatch('search_rates/getSearchRates');
      this.isLoaded = false;
      this.activeTab = null;
      this.structureComments = [];

      let firstGroup = this.groups.find(() => true);
      this.getComments(firstGroup.id)
    },

    closeModal() {
      this.$bvModal.hide('modal-multi-3');
    },

    async getComments(groupId) {
      this.data.author_id = await this.getUserIds();

      this.groups.forEach(group => {
        this.checkLocalData(group.id, this.data);
      });

      this.data.group_id = [groupId];

      let childKey = this.generatePreviewDbKeyWithParent(this.data, 'full');

      let dbResults = await Result.getResultByKey(childKey);

      this.activeTab = groupId;
      this.data.group_id = [groupId];

      if (typeof dbResults === 'undefined') {
        this.isLoaded = false;
        await this.getRemoteData();
      }

      if (typeof dbResults !== 'undefined') {
        this.$store.commit('search_comment/setLocalTelegramComments', dbResults.result.results);
        this.$store.commit('search_comment/setMeta', dbResults.result.meta);
        this.isLoaded = true;
      }

      this.matches_count = this.telegramGroupComments.matches_count;
      this.offset = this.telegramGroupComments.offset;
      this.request_id = this.telegramGroupComments.request_id;
      this.source_id = this.telegramGroupComments.source_id;

      this.currentGroup = this.telegramGroupComments.find(Boolean).relative_results.groups.find(group => group.id === groupId);
      buildTreeComments.call(this);

      this.updateOrAddGroupStatus(groupId, true);
    },

    async getUserIds() {
      let dbUsers = await Result.getResult(Number(this.$route.params.id));

      return await dbUsers.result.results.filter(item => {
        return item.source === "Telegram_scr_2023";
      }).flatMap(item => {
        return item.search_results.map(subItem => subItem.id);
      });
    },

    async getRemoteData() {
      await this.$store.dispatch('search_comment/getTelegramGroupComments', this.data).catch(error => {
        this.$toast.error(this.$t('errors.internal_server_error'));
        this.$store.commit('search_comment/setClearTelegramGroupComments')
        this.structureComments = [];
      });

      this.isLoaded = true;

      let childKey = this.generatePreviewDbKeyWithParent(this.data, 'full');

      for (const telegramGroupComment of this.telegramGroupComments) {
        let payload = {
          cache_search_enabled: true,
          online_search_enabled: false,
          value: this.data.author.value,
          sources: telegramGroupComment.sources,
          childKey: childKey,
          countResult: telegramGroupComment.total_count,
          requestBody: this.data,
        }

        telegramGroupComment['param'] = this.data.author.param;

        await this.$store.dispatch('contacts/cacheWithResultsChild', {
          data: this.telegramGroupComments,
          type: 'telegram_monitor',
          parentKey: this.$store.getters["contacts/meta"].key,
          payload
        });
      }
    },
  }
}
</script>

<style scoped>

</style>