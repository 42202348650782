import {BaseValidateDto} from "@/mixins/dto/BaseValidateDto";

export default class ExportTableMainDto extends BaseValidateDto{
    /**
     * @param {string} date - Date of record.
     * @param {Array<string>} data - Contains details about the user.
     * @param {Array<string>} photos - List of photos.
     * @param {string} text - text.
     * @param isPhoto
     */
    constructor(date, data, photos, text, isPhoto = true) {
        super();
        this.date = this.validateDate(date);
        this.data = this.validateArray(data, 'name');
        this.photos = this.validateArray(photos, 'photos');
        this.text = this.validateString(text, 'text');
        this.isPhoto = isPhoto;
    }
}