<template>
  <b-modal
      id="payment-warning-modal"
      size="md"
      :title="`${$t('search.search_results_cost_total')} ${costDownload}K`"
      scrollable
      hide-footer
      centered
      @hide="close"
  >
    <div class="d-grid">
      <h5>{{ $t('comment_search.modal_payment_text') }}</h5>
      <ul class="m-l-10">
        <li v-for="activityGroup in activityGroups">{{activityGroup.data.find(Boolean).value}}</li>
      </ul>
      <h5 class="m-t-20">{{ $t('comment_search.download_cost_total', {credit: costDownload}) }}</h5>

      <b-button class="mt-3" variant="primary" block @click="confirm">{{ $t('modal.actionButton') }}</b-button>
      <b-button class="mt-2" variant="secondary" @click="close" block>{{ $t('modal.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import {setPluralize} from "@/mixins/localization";

export default {
  name: 'PaymentWarningModal',

  props: {
    costDownload: {
      type: Number,
      required: true,
    },
    countNotDownload: {
      type: Number,
      required: true,
    },
    local: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    }
  },

  computed: {
    activityGroups() {
      return this.groups.filter((item) => item.count);
    }
  },

  mounted() {
    this.$bvModal.show('payment-warning-modal');
  },

  methods: {
    setPluralize,

    close() {
      this.$bvModal.hide('payment-warning-modal');
      this.$emit('close');
    },

    confirm() {
      this.$bvModal.hide('payment-warning-modal');
      this.$emit('confirm');
    }
  },
}
</script>

<style scoped>

</style>