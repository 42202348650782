const state = {
    progress: 0,
}
const getters = {
    progress: state => state.progress,
}

const mutations = {
    setProgress(state, progress) {
        state.progress = progress
    },

    setClearProgress(state) {
        state.progress = 0;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations
}