import moment from "moment";

export class BaseValidateDto {
    /**
     * @param {any} value
     * @param {string} fieldName
     * @returns {string}
     */
    validateString(value, fieldName) {
        if (typeof value !== 'string' && typeof value !== 'undefined') {
            throw new Error(`Invalid ${fieldName}: Expected a non-empty string.`);
        }

        return typeof value === 'undefined' ? '' : value.trim();
    }

    /**
     * @param {any} value
     * @param {string} fieldName
     * @returns {Array}
     */
    validateArray(value, fieldName) {
        if (!Array.isArray(value)) {
            throw new Error(`Invalid ${fieldName}: Expected an array.`);
        }
        return value;
    }

    /**
     * @param {any} value
     * @returns {string}
     */
    validateDate(value) {
        if (typeof value === 'undefined') {
            throw new Error('Invalid date: Expected a non-empty string.');
        }

        if (typeof value !== 'number') {
            return value.trim();
        }

        return convertToDate(value);
    }
}

export function convertToDate(infoDate) {
    let text;
    if (Number.isInteger(infoDate)){
        text = moment(infoDate, 'X').format('DD.MM.YYYY, HH:mm')
    } else if (infoDate.length === 10 && infoDate.includes('-')){
        text = moment(infoDate, 'YYYY-MM-DD').isValid()
            ? moment(infoDate, 'YYYY-MM-DD').format('HH:mm') === '00:00' ?
                moment(infoDate, 'YYYY-MM-DD').format('DD.MM.YYYY') :
                moment(infoDate, 'YYYY-MM-DD').format('DD.MM.YYYY HH:mm')
            : infoDate

    } else if(infoDate.length === 10 && infoDate.includes('.')) {
        text = moment(infoDate, 'DD.MM.YYYY').isValid()
            ? moment(infoDate, 'DD.MM.YYYY').format('HH:mm') === '00:00' ?
                moment(infoDate, 'DD.MM.YYYY').format('DD.MM.YYYY') :
                moment(infoDate, 'DD.MM.YYYY').format('DD.MM.YYYY HH:mm')
            : infoDate
    } else {
        if (!moment(infoDate).isValid()){
            text = infoDate;
        } else {
            text = moment(infoDate).format('HH:mm') === '00:00' ?
                moment(infoDate).format('DD.MM.YYYY') :
                moment(infoDate).format('DD.MM.YYYY HH:mm');
        }
    }

    return text;
}
