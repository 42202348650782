<template>
  <b-dropdown right :disabled="isDownloadHtml || !isLoaded" id="dropdown-1" variant="primary" class="m-md-2 btn-primary">
    <template #button-content>
      <b-spinner small v-if="isDownloadHtml"></b-spinner>
      {{ $t('pdf.save_pdf') }}
    </template>

    <b-dropdown-item @click="create()" :disabled="!(typeof activeTab === 'string')">
      <i class="feather icon-download"></i>
      <span style="padding-left: 4px;">{{ $t("comment_search.save_html_group") }}</span>
    </b-dropdown-item>
    <b-dropdown-item @click="create(true)">
      <i class="feather icon-download"></i>
      {{ $t("comment_search.save_html_groups", {countGroup: countGroup}) }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'CommentBtnExport',

  props: {
    isLoaded: {
      type: Boolean,
      default: false
    },
    activeTab: {
      type: String|null,
      required: true
    },
    isDownloadHtml: {
      type: Boolean,
      default: false
    },
    countGroup: {
      type: Number,
      default: 0
    }
  },

  methods: {
    create(exportAll) {
      this.$emit('create', exportAll)
    }
  }
}
</script>

<style scoped>

</style>