<template>
  <div>
    <div class="m-t-20 m-b-20">
      <b-skeleton width="200px"/>
    </div>

    <b-skeleton-table
        :rows="1"
        :columns="3"
        :table-props="{ bordered: true, striped: true }"
    />
    <b-skeleton width="200px"/>
    <div class="d-flex justify-content-center">
      <b-skeleton width="200px" height="35px"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PreviewSkeleton',
}
</script>

<style scoped>

</style>