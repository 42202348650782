<template>
  <div>
    <div v-for="item in data">
      <span v-if="item.param !== 'instagram_id'">
        {{ $t(`params.${item.param}`) }}:
        <span v-if="item.param === 'username'">
           <a class="parent__relation__link" :href="`https://www.instagram.com/${item.value}`" style="text-decoration: underline" target="_blank">{{ item.value }}</a>
        </span>
        <span v-else-if="item.param === 'social_url'">
           <a class="parent__relation__link" :href="item.value" style="text-decoration: underline" target="_blank">{{ item.value }}</a>
        </span>
        <span v-else>
          {{ item.value }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OtherRelation',

  props: {
    data: {
      type: Array,
      required: true
    }
  }
}

</script>

<style scoped>
.parent__relation__link {
  font-size: .9375rem;
}
</style>