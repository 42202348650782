<template>
  <div class="relation-modal">
    <b-modal
        :size="relationName === Relation.COMMUNITIES ? 'xl' : 'xl'"
        body-class="h-80"
        scrollable
        :id="`relations-modal-${relationId}`"
        centered
        hide-footer
        ref="relationScroll"
        @show="open"
        @hide="close"
    >
      <template #modal-title>
        <span v-if="!relations.length"><b-skeleton width="150px"/></span>
        <span v-else>{{ $t(`relations.${relationName}`) }}: {{ relations.length }}</span>
      </template>

      <b-button :pressed.sync="isOpenAll" variant="primary" class="m-l-10" v-if="relations.length > 12 && relationName === Relation.COMMUNITIES">
        <span v-if="!isOpenAll">{{ $t('relations.btn.all_view') }}</span>
        <span v-if="isOpenAll">{{ $t('relations.btn.all_hide') }}</span>
      </b-button>

      <relation-skeleton-list :count="relations.length" />

      <div class="d-flex flex-wrap parent__relation">
        <div
            v-for="relation in relations"
            class="d-flex align-items-center m-1 b-radius-5 relation p-10"
        >
          <image-profile
              class="m-r-20"
              size="60px"
              @preview="openModal(relation.relation.photos.find(Boolean))"
              :payload="relation.relation.photos.find(Boolean)?.image"
          />

          <div class="w-100" style="min-width: 0;">
            <other-relation v-if="relationName !== Relation.COMMUNITIES" :data="relation.relation.data"/>
            <communication-relation
                v-if="relationName === Relation.COMMUNITIES"
                :id="relation.relation.id"
                :data="relation.relation.data"
                :total="relations.length"
                :isOpenAll="isOpenAll"
            />
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-evenly m-t-20 m-b-20" v-if="loaded">
        <b-spinner />
      </div>
    </b-modal>

    <b-modal v-model="isOpenModal"
             size="xl"
             width="960"
             centered
             scroll="keep"
             hide-footer>
      <face-inner :img-base="{}"  :meta="{}" :photo="currentPhoto"/>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ImageProfile from "@/components/ImageProfile.vue";
import FaceInner from "@/components/results/FaceInner.vue";
import SearchComment from "@/components/search/comments/search_comment.vue";
import {Relation} from "@/enums/relation";
import OtherRelation from "@/components/relations/component/other.vue";
import CommunicationRelation from "@/components/relations/component/communication.vue";
import RelationSkeletonList from "@/components/relations/component/SkeletonList.vue";

export default {
  name: 'relation-modal',
  components: {RelationSkeletonList, CommunicationRelation, OtherRelation, SearchComment, FaceInner, ImageProfile},

  props: {
    searchParam: {
      type: Object,
      required: true,
    },
    relationName: {
      type: String,
      required: true,
    },
    relationId: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      loaded: false,
      isOpenModal: false,
      isOpenAll: false,
      offset: 30,
      currentPage: 1,
      endIndex: 0,
      currentPhoto: '',
    }
  },

  computed: {
    Relation() {
      return Relation
    },
    ...mapGetters('relation_entity', ['relations', 'total', 'count'])
  },

  mounted() {

  },

  methods: {
    openModal(currentPhoto) {
      if (!currentPhoto) return;
      this.currentPhoto = currentPhoto;
      this.isOpenModal = !this.isOpenModal;
    },

    async open() {
      await this.loadRelation();

      this.$nextTick(() => {
        this.$refs.relationScroll.$refs.body.addEventListener('scroll', (event) => {
          if (this.$refs.relationScroll.$refs.body.scrollTop + this.$refs.relationScroll.$refs.body.clientHeight >= this.$refs.relationScroll.$refs.body.scrollHeight - 10) {
            if (this.loaded)  return;

            this.loaded = true;
            this.loadItems();
          }
        });
      })
    },

    close() {
      this.currentPage = 1;
      this.endIndex = 0;
      this.loaded = false;
    },

    async loadRelation() {
      await this.$store.dispatch('relation_entity/getRelations', {relationId: this.relationId, body: {limit: this.offset, offset: 0, relation_name: this.relationName}});
    },

    async loadItems() {
      if (this.count < this.offset) {
        this.loaded = false;
        return;
      }

      this.endIndex = this.currentPage * this.offset;
      this.currentPage++;

      await this.$store.dispatch('relation_entity/getPaginateRelations', {relationId: this.relationId, body: {limit: this.offset, offset: this.endIndex, relation_name: this.relationName}});
      this.loaded = false;
    }
  }
}
</script>

<style>
.relation {
  background: #ebebeb;
  flex: 0 0 calc(33.333% - 10px);
  max-width: calc(33.333% - 10px);
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  box-sizing: border-box;
}

.modal-dialog-scrollable .modal-body {
  max-height: 840px!important;
}

@media (min-width: 990px) and (max-width: 1200px) {
  .relation {
    flex: 0 0 48%;
    max-width: 48%;
  }
}

@media (max-width: 990px) {
  .relation {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
</style>