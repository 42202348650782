<template>
  <div>
    <div class="card" style="margin-top: 1rem; margin-bottom: 0">
      <div class="card-header d-flex flex-lg-row flex-column" style="padding: 0;">
        <div class="media data-description m-r-auto align-items-center" style="display: flex;">
          <figure class="media-left ">
            <p class="image" v-if="!actionLink.length">
              <img :src="result.icon" :alt="result.param">
            </p>
            <p class="image" v-else>
              <a :href="actionLink" target="_blank" v-tippy="{ placement : 'top',  arrow: true }"
                 :content="$t('contact.gotoapp')+ ' ' + app">
                <img :src="result.icon" :alt="result.param">
              </a>
            </p>
          </figure>
          <div class="media-content" style="width: 100%">
            <div class="content">
              <h2 v-if="!actionLink.length">
                {{ typeof result.source_locales !== 'undefined' ? result.source_locales[locale] : result.source }}: {{ result.param === 'image' ? '' : result.value }}
              </h2>
              <h2 v-else>
                <a :href="actionLink" v-tippy="{ placement : 'top',  arrow: true }"
                   :content="$t('contact.gotoapp')+ ' ' + result.source" target="_blank">
                  {{ typeof result.source_locales !== 'undefined' ? result.source_locales[locale] : result.source }}: {{result.value }}
                </a>
                <a :href="actionLink" target="_blank" v-tippy="{ placement : 'top',  arrow: true }"
                   :content="$t('contact.gotoapp')+ ' ' + app"><i class="feather icon-external-link"></i></a>
              </h2>
            </div>
          </div>
        </div>
        <div class="controlsContainer" style="display: flex; align-items: center; justify-content: space-between">
          <div v-if="typeof result.rating !== 'undefined'" style="display: flex;align-items: center;margin-right: 5px">
            <source-rating
                :rating="result.rating"
                :rating-details="result.rating_details"
            ></source-rating>
          </div>
          <div class="contact-control pb-2 pb-xl-0">
  
            <i
                v-if="typeof result.additional_options !== 'undefined' || typeof result.additional_search !== 'undefined'"
                :id="result.value"
                style="margin-left: 0.4rem;margin-right: 0.4rem; font-size: 20px; cursor: pointer"
                @click.self="updateSource(result)"
                :content="$t('result.update_result')"
                v-tippy="{ placement : 'top',  arrow: true }"
                class="feather icon-refresh-cw"
                :class="{'rotating' : rotate }"
            />
            <i
                :id="result.value"
                style="margin-left: 0.4rem;margin-right: 0.4rem;font-size: 20px; cursor: pointer"
                v-if="result.source !== 'GetContact'"
                @click.self="editMode(paginatedItems)"
                :content="$t('result.edit_result')"
                v-tippy="{ placement : 'top',  arrow: true }"
                class="feather icon-edit"
                :class="{'text-success': editResults}"
            >
            </i>
            <i
                style="margin-left: 0.4rem;margin-right: 1rem; font-size: 20px; cursor: pointer"
                @click="deleteSelectedResult"
                class="feather icon-x"
                :content="$t('result.delete_source')"
                v-tippy="{ placement : 'top',  arrow: true }"
            />
          </div>
        </div>

      </div>
    </div>
    <div class="card-block" style="margin: 0.5em !important; padding-bottom: 1rem !important;">
      <div class="search-photo-container" v-if="result.param === 'image'">
        <image-profile :payload="result.value"/>
      </div>
      <template v-if="searchResults">
        <nav class="panel" v-for="(infoResult, index) in searchResults.search_results" :key="index" :id="index">

          <div class="row flex-column-reverse flex-lg-row">
            <div>
              <div class="panel-heading" :id="index" v-if="infoResult.info_date">{{ setCorrectDate(infoResult.info_date) | moment('DD.MM.YYYY, HH:mm') }}<span style="margin-left: 1rem">{{resultDateState(infoResult)}}</span></div>
              <InfoBlock v-for="(param, i) in sortByGroups(infoResult.data)" :key="i" :id="index"
                         :dataParam="param" :resultTitle="result.param" :resultSource="result.source" />
            </div>
            <div class="col-lg-7" v-if="typeof infoResult.photos !== 'undefined' && infoResult.photos.length">
              <CardFooter :photos="infoResult.photos" :meta="meta" :source="result.source" />
            </div>

            <template v-if="result.source === 'InternetArchive'">
              <internet-archive v-for="(infoResult, index) in paginatedItems" :key="index" :result="infoResult"></internet-archive>
            </template>
            <template v-else>
              <nav class="panel" v-for="(infoResult, index) in paginatedItems" :key="index" >
                <div class="panel-heading" v-if="infoResult.info_date" style="display: flex; align-items: center">
                  <div >
                    <b-form-checkbox
                        style="width: 15px; height: 15px; margin-right: 0.5rem;padding: 0;padding-top: 1px"
                        v-model="infoResult.selected"
                        v-if="editResults"
                        @input="markCurrentElement()"
                    >
                    </b-form-checkbox>
                  </div>
                  <div style="display: flex; align-content: center;justify-content: space-between; width: 100%;align-items: center">
                    <div style="line-height: 1.2rem" >
                      {{ setCorrectDate(infoResult.info_date) | moment('DD.MM.YYYY, HH:mm') }}<span style="margin-left: 1rem">{{resultDateState(infoResult)}}</span>
                    </div>
                    <div v-if="editResults" class="media-right" @click="removeSelectedItem(infoResult)">
                      <i :content="$t('result.remove_element')" v-tippy="{ placement : 'top',  arrow: true }" class="feather icon-x"></i>
                    </div>
                  </div>


                </div>
                <div class="row flex-column-reverse ">

                  <div>
                    <InfoBlock v-for="(param, i) in sortByGroups(infoResult.data)" :key="i" :dataParam="param"
                               :resultTitle="result.param"
                               :resultSource="result.source"  />
                  </div>
                  <div v-if="typeof infoResult.photos !== 'undefined' && infoResult.photos.length">
                    <CardFooter :photos="infoResult.photos" :meta="meta"/>
                  </div>
                </div>
              </nav>
            </template>
          </div>

        </nav>
      </template>
      <template v-else>
        <div v-if="show">
          <b-pagination
              style="margin: 0.5rem"
              align="center"
              v-if="result.search_results && result.search_results.length > 3"
              :total-rows="total.length"
              v-model="current"
              :per-page="perPage"
              order="is-centered"
              @input="onChange"
          >
          </b-pagination>
          <div class="contact-controls-buttons" v-if="editResults">
            <b-button class="button" variant="danger" @click="removeSelectedItems()">{{ $t("result.delete_selected") }}</b-button>
            <b-button v-if="result.search_results && result.search_results.length > 3" class="button"  @click="selectAll(false)">{{ $t("result.remove_all") }}</b-button>
            <b-button v-if="result.search_results && result.search_results.length > 3" class="button"  @click="selectAll(true)">{{ $t("result.select_all") }}</b-button>
          </div>

          <template v-if="result.source === 'InternetArchive'">
            <internet-archive v-for="(infoResult, index) in paginatedItems" :key="index" :result="infoResult"></internet-archive>
          </template>
          <template v-else>
            <nav class="panel" v-for="(infoResult, index) in paginatedItems" :key="index" >
              <div class="panel-heading" v-if="infoResult.info_date" style="display: flex; align-items: center">
                <div >
                  <b-form-checkbox
                      style="width: 15px; height: 15px; margin-right: 0.5rem;padding: 0;padding-top: 1px"
                      v-model="infoResult.selected"
                      v-if="editResults"
                      @input="markCurrentElement()"
                  >
                  </b-form-checkbox>
                </div>
                <div style="display: flex; align-content: center;justify-content: space-between; width: 100%;align-items: center">
                  <div style="line-height: 1.2rem" >
                    {{ setCorrectDate(infoResult.info_date) | moment('DD.MM.YYYY, HH:mm') }}<span style="margin-left: 1rem">{{resultDateState(infoResult)}}</span>
                  </div>
                  <div v-if="editResults" class="media-right" @click="removeSelectedItem(infoResult)">
                    <i :content="$t('result.remove_element')" v-tippy="{ placement : 'top',  arrow: true }" class="feather icon-x"></i>
                  </div>
                </div>


              </div>
              <div class="row flex-column-reverse ">

                <div>
                  <InfoBlock v-for="(param, i) in sortByGroups(infoResult.data)" :key="i" :dataParam="param"
                             :resultTitle="result.param"
                             :resultSource="result.source"  />
                </div>
                <div v-if="typeof infoResult.photos !== 'undefined' && infoResult.photos.length">
                  <CardFooter :photos="infoResult.photos" :meta="meta"/>
                </div>
              </div>
            </nav>
          </template>

        </div>

      </template>
    </div>
    <b-modal ref="delete-modal" class="delete-modal" hide-footer centered :title="deleteModalTitle" :size="showGraph ? 'lg' : 'md' ">
      <div class="delete-body">
        <graph
            v-if="showGraph"
            :itemsForDelete="itemsForDelete"
            :disableDelete="true"
        />
      </div>
      <div class="d-grid">
        <b-button class="mt-3" variant="danger" block @click="deleteResult">{{ $t("modal.actionDelete") }}</b-button>
        <b-button class="mt-2" variant="secondary" block @click="close">{{ $t('modal.cancel') }}</b-button>
      </div>
    </b-modal>


  </div>
</template>
<script>
import Graph from "@/components/results/graph";
import CardFooter from "@/components/results/CardFooter";
import InfoBlock from "@/components/results/InfoBlock";
import ImageProfile from "./ImageProfile";
import resultsToLevels from "@/mixins/resultsToLevels";
import resultDateState from "@/mixins/resultDateState";
import deleteSearchResultsController from "@/mixins/deleteSearchResultsController"
import InternetArchive from "@/components/InternetArchive.vue";
import GraphTooltip from "@/components/results/graphTooltip";
import SourceRating from "@/components/results/sourceRating";
export default {
  props: ['result', 'meta','parentSources','item','index'],
  mixins:[resultsToLevels, resultDateState, deleteSearchResultsController],
  components: {
    GraphTooltip,
    InternetArchive,
    ImageProfile,
    CardFooter,
    InfoBlock,
    SourceRating,
    Graph
  },
  name: "ContactGroupScan",
  data() {
    return {
      searchResults: null,
      current: 1,
      perPage: 3,
      show: true,
      showModalDialog: false,
      showModalDialogForSearchResult: false,
      deleteModalTitle: '',
      itemsForDelete: [],
      itemsForDeleteSearchedResult: [],
      editResults : false,
      payload: {
        keyVal: this.meta.key,
        result: this.result
      },
      showGraph: false,
      rotate: false
    }
  },

  computed:{
    total() {
      let arr = [];
      if(this.result.search_results) {
        this.result.search_results.forEach(searchResult => {
          searchResult.type = this.result.type
          arr.push(searchResult)
        })
      }
      return arr;
    },
    paginatedItems() {
      let page_number = this.current-1
      return this.total.slice(page_number * this.perPage, (page_number + 1) * this.perPage);
    },
    results() {
      return this.$route.name === 'Result' ? this.$store.state.contacts.results.results:
          this.$store.state.contacts.childResults.results
    },
    locale() {
      return this.$i18n.locale === 'ua' ? 'uk': this.$i18n.locale
    },
    actionLink() {
      if(this.result.source === 'WhatsApp' && this.result.param === 'isdn') {
        return 'https://wa.me/' + this.result.value.replace(/\+/, '');
      } else if((this.result.source === 'Telegram' || this.result.source.toLowerCase().includes("telegram")) && this.result.param === 'isdn') {
        if(this.result.param !== 'telegram_id') {
          return 'https://t.me/' + this.result.value;
        }
        return '';
      } else if(this.result.source === 'Viber' && this.result.param === 'isdn') {
        return 'viber://chat?number='+ encodeURIComponent(this.result.value);
      }
      return '';
    },
    app() {
      if(this.result.source === 'Viber' && this.result.param === 'isdn') {
        return 'Viber'
      } else if(this.result.source === 'WhatsApp' && this.result.param === 'isdn') {
        return 'WhatsApp'
      } else if((this.result.source === 'Telegram' || this.result.source.toLowerCase().includes("telegram")) && this.result.param === 'isdn') {
        return 'Telegram';
      }
      return '';
    },
  },
  methods: {
    setCorrectDate(date){
      let temp;
      if (date[2] === '.'){
        temp = date.slice(3,6) + date.slice(0,3) + date.slice(6,9)
      } else {
        temp = date;
      }
      return temp
    },

    markCurrentElement(){
      this.updateResult()
    },
    selectAll(state){
      if(this.result.search_results) {
        this.result.search_results.forEach(item => {
          item.selected = state
        })
      }
      this.updateResult()
    },



    updateResult(){
      this.editResults =! this.editResults
      this.editResults =! this.editResults
    },
    deleteResult(){
      let payload = {
        keyVal: this.meta.key,
        result: this.result
      }
      this.$store.dispatch('contacts/setRemoveSourceResult', payload).then(()=>{
        this.$store.dispatch('contacts/updateResult', payload);
      })
      if (this.itemsForDelete.length ){
        this.results.forEach(result =>{
          this.itemsForDelete.forEach(item=>{
            if (result.value === item.value){
              let payload = {
                keyVal: this.meta.key,
                result: result
              }
              this.$store.dispatch('contacts/setRemoveSourceResult', payload).then(()=>{
                this.$store.dispatch('contacts/updateResult', payload);
              })
            }
          })
        })
      }
    },
    close(){
      this.$refs['delete-modal'].hide();
    },
    onChange(){
      this.show = false;
      this.$nextTick(()=>{
        this.show = true;
      })
    },

    setDialogMessage(itemsForDelete){
      let temp = []
      itemsForDelete.forEach(item => {
        if (!temp.length){
          temp.push(item.value)
        } else {
          if (temp.indexOf(item.value) === -1){
            temp.push(item.value)
          }
        }
      })
      let message;
      if (this.result.level !== 3 && itemsForDelete && itemsForDelete.length){
        let tempMessage = this.$t('graph.dialog_alert_text')
        temp.forEach((item, index)=>{
          if (index === temp.length -1){
            tempMessage = tempMessage+item
          } else {
            tempMessage = tempMessage+item+', '
          }
        })
        message = tempMessage + this.$t('graph.continue');
      } else {
        message = this.$t('graph.remove_result');
      }
      this.deleteModalTitle = message
      return message;
    },
    editMode(){
      this.editResults = !this.editResults;
    },
    updateSource(result){
      this.rotate = true;
      let payload = {
        keyVal: this.meta.key,
        result: this.result
      }
      let id;
      if (typeof result.additional_options !== 'undefined'){
        id = result.additional_options.service_id
      } else {
        id = result.additional_search.service_id
      }
      let obj = {
        payload: {
          'param': result.param,
          'value': result.value,
        },
        service_id: id,
        source: result.source,
        key: this.meta.key
      }
      this.$store.dispatch('contacts/updateSource', obj).finally(()=>{
        this.rotate = false;
        this.$store.dispatch('contacts/updateResult', payload);
        this.updateResult()
      })
    },


    deleteSelectedResult(){
      this.showModalDialog = true;
      this.itemsForDelete = [];
      if (this.result.level !== 3 ){
        let childLevel = this.levels[this.result.level]
        if (typeof childLevel !== 'undefined'){
          childLevel.forEach(child =>{
            if (child.parentSources.includes(this.result.source)){
              if (child.parentSources.length === 1){
                if (this.result.value === child.parentValue){
                  this.itemsForDelete.push(child)
                }
                if (typeof child.children !== 'undefined' &&  child.children.length > 0 ){
                  let childLevel = this.levels[child.level]
                  childLevel.forEach(childOfChild =>{
                    if (childOfChild.parentSources.length === 1 && child.value === childOfChild.parentValue){
                      this.itemsForDelete.push(childOfChild)
                    }
                  })
                }
              }
            }
          })
        }
      }
      this.setDialogMessage(this.itemsForDelete)
      this.setDialogMessage(this.itemsForDelete);
      if (typeof this.itemsForDelete !== 'undefined' && this.itemsForDelete.length){
        this.showGraph = true;
      }
      this.$refs['delete-modal'].show();
      try {
        this.$matomo.trackEvent('Web Edit Result', 'WasClicked');
      } catch (e) {
        console.log('ignore error');
      }
    },
    getContactSort() {
      if(this.result.source === 'GetContact' && this.result.search_results.length > 1) {
        let results = [];
        let photos = [];
        for (let searchItem of this.result.search_results) {
          let items = searchItem.data.map(item => {
            return Object.assign(item, {date: searchItem.info_date})
          })
          if (searchItem.photos.length){
            searchItem.photos.forEach(photo =>{
              photos.push(photo)
            })
          }
          results.push(...items);
        }
        const dynamicSort = (property) => {
          let sortOrder = 1;
          if(property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
          }
          return function (a,b) {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
          }
        };
        results = results.sort(dynamicSort('date'))
        results = results.filter((item,pos) => {
          return results.findIndex(x => x.value === item.value) === pos
        })

        this.searchResults = Object.assign({}, this.result, {
          search_results: [
            {
              data: results,
              photos: photos,
              info_date: results[results.length -1].date
            }
          ]
        })
      }
    },
    sortByGroups(data) {
      let groups = [];
      const other = [];
      for(let item of data) {
        if(item.param === 'group') {
          groups.push(item);
        } else {
          other.push(item);
        }
        //
      }
      // groups = groups.sort((a, b) => {
      //   const resA = a.value.match(/\d{4}(\D)\d{2}\1\d{2}/g);
      //   const resB = b.value.match(/\d{4}(\D)\d{2}\1\d{2}/g);
      //   return new Date(resB[0]) - new Date(resA[0]);
      // })
      return [...other, ...groups];
    }
  },
  mounted() {
    this.getContactSort();
  }
}
</script>
<style lang="scss" scoped>
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

.m-r-auto {
  margin-right: auto;
}
.card-header{
  padding: 10px 20px;
}
.card-block{
  padding: 0.5em !important;
}
.media{
  padding: 10px;
  margin-bottom: 0.5em;
}
.collapse{
  padding: 10px;
}
.panel-block{
  padding-left: 10px;
  padding-right: 10px;
  line-height: 2em;
  display: flex;
  justify-content: space-between;
}
.panel-block:not(:last-child){
  border-bottom: 1px solid #ededed;
}
.panel{
  //padding-bottom: 15px;
}

.media-left{
  margin-bottom: 0;
  margin-right: 8px;
}
.image{
  img {
    width: 24px;
    height: 24px;
  }
}
h2 {
  margin-top: 10px;
  font-size: 1.5em;
  color: #363636;
  font-weight: 600;
  line-height: 1.125;
  a {
    font-size: inherit;
  }
}
.panel-heading{
  background-color: #ededed;
  border-radius: 6px 6px 0 0;
  color: #363636;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
  text-align: left;
  font-size: .75em;
}
.card-block{
  border-radius: 6px;
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  font-size: 1rem;
  padding: 0 !important;
}

.contact-control {
  display: flex;
  justify-content: flex-end;
  align-items: center
}

.contact-controls-buttons {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-left: 8px;
  padding-right: 8px;
  margin-top: 0.5rem;
}

.contact-controls-buttons button {
  margin-bottom: 0.5rem;
  width: 33%;
  text-transform: none;
}

.contact-controls-buttons button:last-child {
  width: 31%;
}

.media-left {
  width: 24px;
  height: 24px;
}

@media (max-width: 992px) {
  .media-left img {
    width: 24px;
    height: 24px;
  }
  .contact-controls-buttons {
    flex-direction: column-reverse;
  }
  .contact-controls-buttons button, .contact-controls-buttons button:last-child {
    width: 100%;
  }
  .contact-control {
    width: 100%;
    flex: 1 0 100%;
    justify-content: center;
    margin-top: 5px;
  }
  .media-left {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }
  .media-content {
    flex: 1 1 50%;
    h2 {
      margin-top: 0;
      margin-bottom: 0;
      word-break: break-word;
    }
  }
  .data-description {
    flex-wrap: wrap;
  }
  .content h2 {
    font-size: 1.2em;
  }
}
</style>