<template>
  <div class="w-100">
    <div class="list-group-item info-block-contact" v-for="countLabel in Object.keys(relations)" :key="countLabel">
      <div class="d-flex align-items-center justify-content-between">
        <div>{{ $t(`relations.${countLabel}`) }}: {{relations[countLabel]}}</div>
        <b-button
            @click="showRelation(countLabel)"
            class="btn btn-primary m-r-10 m-b-5 m-t-5"
        >
          {{ $t('ui.detail') }}
        </b-button>
      </div>
    </div>

    <relation-modal :relation-id="relationId" :search-param="searchParam" :relation-name="relationName" />
  </div>
</template>

<script>
import RelationModal from "@/components/relations/RelationModal.vue";
import SearchComment from "@/components/search/comments/search_comment.vue";
import {ContentType} from "@/enums/content_type";

export default {
  name: 'RelationsBlock',

  components: {SearchComment, RelationModal},

  props: {
    relations: {
      type: Object,
      required: true,
    },
    relationId: {
      type: String,
      required: true,
    },
    searchParam: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      relationName: '',
    };
  },

  computed: {
    ContentType() {
      return ContentType
    }
  },

  methods: {
    showRelation(countLabel) {
      this.relationName = countLabel;

      this.$nextTick(() => {
        this.$bvModal.show(`relations-modal-${this.relationId}`);
      });
    }
  },
};
</script>

<style scoped>
</style>