<template>
  <div class="main-comment">
    <overlay :show="isDownloadHtml" />

    <div class="d-grid d-lg-flex justify-content-lg-end btn-save">
      <comment-btn-export
          :active-tab="activeTab"
          :is-download-html="isDownloadHtml"
          :is-loaded="isLoaded"
          :count-group="localDataStatus.length"
          @create="createHtml"
      />
    </div>

    <div class="d-block m-t-30">
      <b-card>
        <b-card-body>
          <b-card>
            <b-card-body>
              <comment-options :meta="meta" />
            </b-card-body>
          </b-card>

          <div class="m-t-20 main-comment">
            <div class="d-lg-flex align-items-stretch">
              <div v-for="result in results" v-if="results.length" class="main-comment__tabs">
                <div v-for="search_result in result.search_results">
                  <div class="d-flex align-items-stretch">
                    <group-tabs
                        :groups="search_result.groups"
                        :is-blocked="isLoaded"
                        @get-data="getComments"
                        :local-exist-data="localDataStatus"
                        :active-group-id="activeTab"
                    />

                    <payment-warning-modal
                        v-if="isPaymentWarning"
                        :cost-download="costDownload"
                        :count-not-download="countNotDownload"
                        :local="$i18n.locale"
                        :groups="search_result.groups"
                        @close="closeModalPayment()"
                        @confirm="confirmModalPayment()"
                    />
                  </div>
                </div>
              </div>

              <comment-list
                  :structureComments="structureComments"
                  :isLoaded="isLoaded"
                  :active-group-id="activeTab"
                  :author-id="authorId"
                  :group="currentGroupInfo"
                  :is-empty-groups="isEmptyGroup"
                  @next-page="handleNextPage"
              />
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
  </div>
</template>

<script>
import CommentTree from "@/components/search/comments/CommentTree.vue";
import {Result} from '@/models/result'
import CommentSearchPagination from "@/components/search/CommentSearchPagination.vue";
import {mapGetters, mapMutations} from "vuex";
import savePdf from "@/mixins/savePdf";
import filters from "@/mixins/filters";
import imageMixin from "@/mixins/imageMixin";
import saveDoc from "@/mixins/saveDoc";
import i18n from "@/i18n";
import {downloadHtml} from "@/mixins/htmlGenerator";
import dbKeyTelegramMonitor from "@/mixins/telegramMonitor";
import CommentList from "@/components/search/comments/components/CommentList.vue";
import PaymentWarningModal from "@/components/search/comments/PaymentWarningModal.vue";
import GroupTabs from "@/components/search/comments/components/GroupTabs.vue";
import {nextPage} from "@/mixins/comments/paginate";
import {buildTreeComments} from "@/mixins/comments/handler";
import Overlay from "@/components/search/comments/components/Overlay.vue";
import {Sources} from "@/enums/sources";
import CommentOptions from "@/components/search/comments/components/options.vue";
import CommentBtnExport from "@/components/search/comments/components/BtnExport.vue";

export default {
  name: "CommentHistory",

  components: {
    CommentBtnExport,
    CommentOptions,
    Overlay, GroupTabs, PaymentWarningModal, CommentList, CommentSearchPagination, CommentTree},

  mixins: [savePdf, filters, imageMixin, saveDoc, dbKeyTelegramMonitor],

  data() {
    return {
      meta: {},
      groups: [],
      localDataStatus: [],
      activeTab: null,
      currentGroupInfo: {},
      authorId: null,
      isDownloadHtml: false,
      structureComments: [],
      isLoaded: true,
      currentPage: 0,
      results: [],
      isPaymentWarning: false,
      isPaymentConfirm: false,
      costDownload: 0,
      countNotDownload: 0,
    }
  },

  mounted() {
    this.getGroups(this.$route.params.id);
  },

  beforeDestroy() {
    this.$store.commit('search_rates/setClearSearchRates');
  },

  computed: {
    ...mapGetters('search_comment', ['telegramGroupComments', 'metaComment', 'recordId']),
    ...mapGetters('search_rates', ['search_rates', 'servicesList']),

    isEmptyGroup() {
      const currentSource = this.results.find(item => item.source === Sources.TELEGRAM);

      if (currentSource) {
        for (const item of currentSource.search_results) {
          for (const group of item.groups) {
            if (group.count > 0) {
              return false;
            }
          }
        }

        return true;
      }
    },
  },

  methods: {
    ...mapMutations('search_comment', ['setLocalTelegramComments', 'setClearTelegramGroupComments']),
    ...mapMutations('search_rates', ['setClearSearchRates']),

    confirmModalPayment() {
      this.isPaymentConfirm = true;
      this.createHtml(true);
    },

    closeModalPayment() {
      this.isPaymentWarning = this.isDownloadHtml = false;
    },

    async createHtml(full = false) {
      if (full && !this.isPaymentConfirm) {
        let countGroupNotLocal = this.localDataStatus.filter(localData => !localData.status);

        if (countGroupNotLocal.length) {
          this.isPaymentWarning = true;
          this.costDownload = this.search_rates.defaults.offline_result_price * countGroupNotLocal.length
          this.countNotDownload = countGroupNotLocal.length

          return;
        }
      }

      let emptyGroups = this.results.flatMap(result =>
          result.search_results.flatMap(item =>
              item.groups.filter(group => !group.count)
          )
      );

      this.isDownloadHtml = true;

      downloadHtml(full, full ? this.meta : this.metaComment, this.token, this.localDataStatus.length, emptyGroups).then(isStatus => {
        for (let i = 0; i < this.localDataStatus.length; i++){
          const result = this.localDataStatus[i];
          this.updateOrAddGroupStatus(result.groupId);
        }

        if (isStatus.error) this.$toast.error(this.$t('errors.internal_server_error'));
        this.isDownloadHtml = !this.isDownloadHtml;
      });

      this.isPaymentWarning = this.isPaymentConfirm = false;
    },

    async getGroups(id) {
      let dbResults = await Result.getResult(Number(id));

      this.meta = dbResults.result.meta;
      this.results = dbResults.result.results;

      this.meta.payload.requestBody.author_id = await this.getUserIds(this.meta.parent);

      this.authorId = this.meta.payload.requestBody.author_id;

      for (const result of this.results) {
        for (const search_result of result.search_results) {
          for (const group of result.relative_results.groups) {
            let groupInfo = search_result.groups.find(g => g.id === group.id);

            if (!groupInfo) {
              groupInfo = { id: group.id};
              search_result.groups.push(groupInfo);
            }

            groupInfo.name = group.data.find(data => data.param === 'name');
            groupInfo.data = group.data;

            if (groupInfo.count) await this.checkLocalData(group.id);
          }
        }
      }

      for (let i = 0; i < this.localDataStatus.length; i++){
        const result = this.localDataStatus[i];

        if (result.status) {
          await this.getComments(result.groupId);
          break;
        }
      }
    },

    async handleNextPage(currentPage) {
      await nextPage.call(this, currentPage, this.meta.payload.requestBody);
      buildTreeComments.call(this);
    },

    async checkLocalData(groupId) {
      this.meta.payload.requestBody.group_id = [groupId];

      let key = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);
      let res = await Result.getResultByKey(key);
      this.updateOrAddGroupStatus(groupId, typeof res !== 'undefined');
    },

    async updateOrAddGroupStatus(id, newStatus = null) {
      if (newStatus === null) {
        this.meta.payload.requestBody.group_id = [id];
        let key = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);

        let dbResults = await Result.getResultByKey(key);
        newStatus = typeof dbResults !== 'undefined'
      }

      const index = this.localDataStatus.findIndex(item => item.groupId === id);

      if (index !== -1) {
        this.localDataStatus[index].status = newStatus;
      } else {
        this.localDataStatus.push({ groupId: id, status: newStatus});
      }
    },

    selectTab(id) {
      this.setClearTelegramGroupComments();
      this.currentPage = 0;
      this.getComments(id);
    },

    async getComments(id) {
      this.isLoaded = false;
      this.activeTab = id;
      this.meta.payload.requestBody.group_id = [id];
      let childKey = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);

      await this.$store.dispatch('search_comment/getLocalTelegramGroupComments', childKey);

      if (!this.telegramGroupComments.length) {
        await this.getRemoteData(id);
        await this.$store.dispatch('search_rates/getSearchRates');
        if (this.isLoaded) return;
      }

      buildTreeComments.call(this);

      this.isLoaded = true;
      this.updateOrAddGroupStatus(id, true);

      this.currentGroupInfo = this.telegramGroupComments.find(item => !!item).relative_results.groups.find(group => group.id === id);
    },

    async getRemoteData() {
      await this.$store.dispatch('search_comment/getTelegramGroupComments', this.meta.payload.requestBody).catch(error => {
        this.$toast.error(this.$t('errors.internal_server_error'));
        this.$store.commit('search_comment/setClearTelegramGroupComments')
        this.structureComments = [];
        this.isLoaded = true;
      });

      if (this.isLoaded) return;

      let childKey = this.generatePreviewDbKeyWithParent(this.meta.payload.requestBody, 'full', this.meta.parent);

      for (const telegramGroupComment of this.telegramGroupComments) {
        let payload = {
          cache_search_enabled: true,
          online_search_enabled: false,
          value: this.meta.payload.requestBody.author.value,
          sources: telegramGroupComment.source,
          childKey: childKey,
          countResult: telegramGroupComment.total_count,
          requestBody: this.meta.payload.requestBody,
        }

        telegramGroupComment['param'] = this.meta.payload.requestBody.author.param;

        await this.$store.dispatch('contacts/cacheWithResultsChild', {
          data: this.telegramGroupComments,
          type: 'telegram_monitor',
          parentKey: this.meta.parent,
          payload
        });
      }
    },

    async getUserIds(key) {
      let dbUsers = await Result.getResultByKey(key);

      return await dbUsers.result.results.filter(item => {
        return item.source === "Telegram_scr_2023";
      }).flatMap(item => {
        return item.search_results.map(subItem => subItem.id);
      });
    },

    selectedLocale() {
      if (i18n.locale === 'ua') {
        return 'uk'
      } else {
        return i18n.locale
      }
    },
  }
}
</script>

<style scoped>
.group-name {
  position: absolute;
  top: 10px;left: 220px;
}

.text-strong {
  font-weight: 600;
}

.main-comment__tab {
  width: 314px
}

@media screen and (max-width: 991px) {
  .main-comment__tab {
    width: 100%
  }
  .group-name {
    position: absolute;
    top: -52px;
    left: 150px;
  }
}
.main-comment >>> .btn {
  text-transform: unset!important;
}
</style>