<template>
    <b-tabs content-class="mt-3" fill lazy style="margin: -16px" >
      <b-tab :title="$t('side_menu.isdn')" active @click="setMenuParam('isdn')">
        <div style="margin: 16px">
          <isdn-form></isdn-form>
        </div>
      </b-tab>
      <b-tab :title="$t('side_menu.ISDN_set')"  @click="setMenuParam('isdn')">
        <div style="margin: 16px">
          <mass-isdn-form></mass-isdn-form>
        </div>
      </b-tab>
      <b-tab :title="$t('side_menu.imsi')"  @click="setMenuParam('imsi')">
        <div style="margin: 16px">
          <imsi-form></imsi-form>
        </div>
      </b-tab>
      <b-tab :title="$t('side_menu.imei')"  @click="setMenuParam('imei')">
        <div style="margin: 16px">
          <imei-form></imei-form>
        </div>
      </b-tab>
    </b-tabs>
</template>

<script>
import IsdnForm from "@/components/search/params/isdnForm";
import MassIsdnForm from "@/components/search/params/massIsdnForm";
import ImsiForm from "@/components/search/params/imsiForm";
import ImeiForm from "@/components/search/params/imeiForm";
export default {
  name: "phoneSearchContainer",
  components: {ImeiForm, ImsiForm, MassIsdnForm, IsdnForm},
  methods: {
    setMenuParam(val){
      this.$store.dispatch('mainMenuState/menuParam', val)
      this.$store.dispatch('contacts/forceUpdateSearchParams');
    },
  },
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0 !important;
}

</style>