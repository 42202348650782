<template>
  <date-picker
      :disabled-date="disabledDate"
      :disabled-calendar-changer="disabledDate"
      v-model="periodSelected"
      type="date"
      :format="format"
      :lang="lang[$i18n.locale]"
      :disabled="isDisabled"
      :placeholder="placeholder"
      :editable="false"
      @input="setCurrentDate(periodSelected)"
  />
</template>

<script>
import DatePicker from 'vue2-datepicker';

export default {
  name: 'UiCalendar',

  components: {DatePicker},

  props: {
    isDisabled: {
      type: Boolean,
      default: false
    },
    periodSelectedTo: {
      type: Date|null,
      default: null
    },
    periodSelectedFrom: {
      type: Date|null,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: 'DD.MM.YYYY'
    },
    value: {
      type: Date | null,
      default: null
    }
  },

  data() {
    return {
      periodSelected: null,
      today: new Date().setHours(0, 0, 0, 0),
      lang: {
        ua: {
          formatLocale: {
            months: ['січень', 'лютий', 'березень', 'квітень', 'травень', 'червень', 'липень', 'серпень', 'вересень', 'жовтень', 'листопад', 'грудень'],
            monthsShort: ['січ', 'лют', 'бер', 'квіт', 'трав', 'черв', 'лип', 'серп', 'вер', 'жовт', 'лист', 'груд'],
            weekdays: ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', 'п’ятниця', 'субота'],
            weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 7
          }
        },
        ru: {
          formatLocale: {
            months: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
            monthsShort: ['янв.', 'февр.', 'март', 'апр.', 'май', 'июнь', 'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'],
            weekdays: ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
            weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            weekdaysMin: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
            firstDayOfWeek: 1,
            firstWeekContainsDate: 1
          }
        },
        en: {
          formatLocale: {
            months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
            monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
            weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
            weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
            firstDayOfWeek: 0,
            firstWeekContainsDate: 1
          }
        }
      }
    }
  },

  watch: {
    value(newValue) {
      this.periodSelected = newValue;
    }
  },

  methods: {
    disabledDate(date) {
      const minDate = this.minDate;
      const maxDate = this.periodSelectedTo || this.today;
      const fromDate = this.periodSelectedFrom || minDate;

      return date < fromDate || date > maxDate;
    },

    setCurrentDate(date) {
      this.$emit('setDate', date);
    },
  }
}
</script>

<style scoped>

</style>