<template>
  <div class="d-flex align-items-center flex-wrap">
    <div v-for="sceliton in 9" class="d-flex m-10 relation" v-if="!count">
      <b-skeleton-img  size="60px"/>
      <div class="m-l-20 w-75">
        <div v-for="data in 3">
          <b-skeleton></b-skeleton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RelationSkeletonList',
  props: {
    count: {
      type: Number,
      required: true,
    }
  }
}
</script>

<style scoped>
.relation {
  flex: 1 1 31%;
}
</style>