import {mapGetters} from "vuex";
import {
    WidthType,
    BorderStyle,
    Document,
    Paragraph,
    Packer,
    TextRun,
    HeadingLevel,
    ImageRun,
    TableRow,
    TableCell,
    Table,
    HeightRule, AlignmentType
}
    from "docx";
import { saveAs } from 'file-saver';
import moment from "moment/moment";
import _ from "underscore"
import { ImageCaching } from "./imageClass";
import ExportTableMainDto from "@/mixins/dto/ExportTableMainDto";
import {
    createLink,
    createParagraph,
    createParagraphText,
    isCheckUrl, optionBorderNone,
    optionBorderStandard
} from '@/mixins/documentSnippets';
import {toastInfo, toastSuccess} from '@/mixins/toast'

export default {
    components: {
        Document, Paragraph, Packer, TextRun, saveAs, BorderStyle, WidthType
    },
    data() {
        return {
            resizedImages: 0,
            startedExport: false,
            promptPdfActive: false,
            formProps: {
                filename: null,
                password: null
            },
            pdfPassword: 'test',
            needPass: true,
        }
    },
    computed: {
        ...mapGetters('images', ['images', 'allImages', 'photoLoaded']),
        ...mapGetters('auth', ['user']),

        photosCount() {
            let photoCount = 0;
            for (let result of this.results) {
                if(result.search_results) {
                    for (let sr of result.search_results) {
                        photoCount += sr.photos.length;
                    }
                }
            }
            return photoCount
        }

    },
    methods: {
        mountActionsDoc() {
            if (this.meta && this.meta.parent && this.meta.payload.param === 'image'){
                this.formProps.fileName = 'image_search_'+ this.meta.key.split('/')[6]
            } else {
                this.formProps.fileName = this.meta.key.includes('geo_search_telegram_point') ? this.meta.key.replace('geo_search_telegram_point', 'whos_around') : this.meta.key;
            }
        },

        resizeImageDoc(base64Str, maxWidth = 400, maxHeight = 400) {
            return new Promise((resolve) => {
                let img = document.createElement('img');
                img.src = base64Str
                img.onload = () => {
                    let canvas = document.createElement('canvas')
                    const MAX_WIDTH = maxWidth
                    const MAX_HEIGHT = maxHeight
                    let width = img.width
                    let height = img.height

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width
                            width = MAX_WIDTH
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height
                            height = MAX_HEIGHT
                        }
                    }
                    canvas.width = Math.floor(width)
                    canvas.height = Math.floor(height)
                    let ctx = canvas.getContext('2d')
                    ctx.drawImage(img, 0, 0, width, height)
                    resolve({image: canvas.toDataURL(), width: width, height: height})
                }
            })
        },

        isEnyResultInLevel(level){
            let count = 0;

            level.items.forEach(item => {
                if (item.search_results){
                    count += item.search_results.length
                }

                if (item.children_search_results){
                    count += item.children_search_results.results.length
                }
            });

            return count > 0
        },

        setAddressLocation(parentItems, row) {
            if(!_.isUndefined(parentItems) && row.param === 'address') {
                const addresses = _.where(parentItems, {param: 'address'});
                const lats = _.where(parentItems, {param: 'latitude'});
                const longs = _.where(parentItems, {param: 'longitude'});
                const indexAdress = _.findIndex(addresses, {value: row.value});
                if(lats.length && longs.length && lats[indexAdress].value !== '-') {
                    return {param: row.param, value:`${row.value} (${parseFloat(lats[indexAdress].value).toFixed(4)}, ${parseFloat(longs[indexAdress].value).toFixed(4)})`};
                } else {
                    return row;
                }
            }
            return row;
        },

        async resultToDocument(paylaod) {
            console.log(this.results)
            this.isLoading = true;
            toastInfo(this.$t('search.doc_preparing'), this, 2000)

            this.formProps.filename = paylaod.filename
            let resizedPhotos = 0;
            let levels = this.getLevels();

            let obj = {children: []};
            let head = true;
            for(let level of levels){
                if (this.isEnyResultInLevel(level)){
                    if(level.items[0].param === 'image' && head) {
                        const imageCache = new ImageCaching();
                        const imageBase = await imageCache.loadNonComponentImage(level.items[0].value)
                        let imageObj = await this.resizeImageDoc(imageBase)
                        obj.children.push(new Paragraph({
                            text: this.$t('search.search_by_photo'),
                            heading: HeadingLevel.TITLE,
                        }));

                        obj.children.push(new Paragraph({
                            children: [
                                new ImageRun({
                                    data: imageObj.image,
                                    transformation: {
                                        width: imageObj.width,
                                        height: imageObj.height
                                    },
                                }),
                            ],
                        }))
                        head = false;
                    } else {
                        if(head) {
                            obj.children.push(new Paragraph({
                                text: this.$t('search.search_results'),
                                heading: HeadingLevel.HEADING_1,
                                alignment: AlignmentType.CENTER
                            }));
                        }
                        head = false;

                        if(this.schemaPic) {
                            obj.children.push(new Paragraph({
                                children: [
                                    new ImageRun({
                                        data: this.schemaPic,
                                        transformation: {
                                            width: this.schemaPicSize.width * 0.75,
                                            height: this.schemaPicSize.height * 0.75
                                        },
                                    }),
                                ],
                            }))
                        }
                        if (typeof level.value !== 'undefined'){
                            let text = this.$t('result.stage')+' '+level.level+': '+ this.$t('result.search_by') +' '+ level.value
                            obj.children.push(new Paragraph({
                                text: text,
                                heading: HeadingLevel.HEADING_1,
                            }))
                        }

                    }

                    for (let result of level.items) {
                        for(let k in result.search_results) {
                            if (result.type === 'database' || result.source === 'FaceRecognition'){
                                obj.children.push(new Paragraph({
                                    text: typeof result.source_locales !== 'undefined' ? result.source_locales[this.selectedLocale] : result.source ,
                                    heading: HeadingLevel.HEADING_3,
                                }))
                            } else {
                                obj.children.push(new Paragraph({
                                    text: typeof result.source_locales !== 'undefined' ? result.source_locales[this.selectedLocale] : result.source ,
                                    heading: HeadingLevel.HEADING_3,
                                }))
                            }

                            if(result.search_results[k].data.length > 0) {
                                if (typeof  result.search_results[k].locations !== 'undefined'){
                                    for(let locationInfo of result.search_results[k].locations) {
                                        let index = 1;
                                        let scecifyingIndex =  this.$t('geo.specifiing') +' # '+ index
                                        let time = moment(locationInfo.info_date, 'X').format('DD.MM.YYYY HH:mm:ss')
                                        let val = time +' '+ locationInfo.address +','+ ( locationInfo.latitude +',' +  locationInfo.longitude)

                                        obj.children.push(new Paragraph({
                                            text: this.$options.filters.capitalize(scecifyingIndex) +': ' + val,
                                        }))
                                        index++
                                    }
                                }
                            } else {
                                obj.children.push(new Paragraph({
                                    text: this.$options.filters.capitalize(this.$t('params.'+'name'))+': ' + 'Нет имени'}))
                            }

                            const tableData = new ExportTableMainDto(
                                result.search_results[k].info_date,
                                result.search_results[k].data.concat(this.prepareInfoCounters(result.search_results[k])),
                                result.search_results[k].photos,
                            );

                            let table = await this.prepareTable(tableData);

                            obj.children.push(table);
                        }

                        if (typeof result.children_search_results === 'object') {
                            await this.prepareChildren(result.children_search_results, obj, resizedPhotos);
                        }
                    }
                }

            }
            this.resizedImages = resizedPhotos;
            const doc = new Document({
                sections: [obj],
            });

            Packer.toBlob(doc).then(blob => {
                saveAs(blob, this.formProps.filename+'.docx');
                this.isLoading = false
                toastSuccess(this.$t('search.doc_saved'), this);
            });

        },

        prepareInfoCounters(data) {
            let params = [];

            if (data.relation_counters) {
                if (data.relation_counters.communities) {
                    params.push({
                        param: 'communities',
                        value: data.relation_counters.communities.toString(),
                    });
                }
                if (data.relation_counters.followers) {
                    params.push({
                        param: 'followers',
                        value: data.relation_counters.followers.toString(),
                    });
                }
                if (data.relation_counters.subscriptions) {
                    params.push({
                        param: 'subscriptions',
                        value: data.relation_counters.subscriptions.toString(),
                    });
                }
            }

            return params;
        },

        async prepareTable(tableData) {
            if (!(tableData instanceof ExportTableMainDto)) throw new Error('tableData is not an instance of ExportTableMainDto');

            const userInfo = this.prepareUserInfo(tableData.data);

            let photosCell = new TableCell({children: []});

            if (tableData.isPhoto) {
                let photos = await this.preparePhoto(tableData.photos);
                photosCell = new TableCell({
                    children: [photos],
                    verticalAlign: "center",
                });
            }

            let messageComment = new TableCell({
                children: [],
            });

            if (tableData.text) {
                messageComment = new TableCell({
                    children: [
                        createParagraphText(tableData.text, '10pt')
                    ],
                });
            }

            return new Table({
                width: { size: 100, type: WidthType.PERCENTAGE },
                borders: optionBorderStandard(),
                columnWidths: [25, 150],
                rows: [
                    new TableRow({
                        tableHeader: true,
                        children: [
                            photosCell,
                            new TableCell({
                                children: [
                                    new Table({
                                        width: { size: 100, type: WidthType.PERCENTAGE },
                                        columnWidths: [50, 150],
                                        borders: optionBorderNone(),
                                        rows: userInfo,
                                    })
                                ],
                            }),
                        ],
                    }),

                    new TableRow({
                        children: [
                            new TableCell({
                                children: [
                                    createParagraphText(tableData.date)
                                ],
                                verticalAlign: "center",
                            }),
                            messageComment,
                        ],
                    }),

                    new TableRow({children: [
                            new TableCell({
                                children: [],
                                verticalAlign: "center",
                            }),
                        ]})
                ],
            });
        },

        prepareUserInfo(userInfo) {
            let childrenCell = [];

            userInfo.forEach(info => {
                let paramLang = this.$t(`params.${info.param}`);

                childrenCell.push(new TableRow({
                    height: {
                        value: 300,
                        rule: HeightRule.AUTO
                    },
                    children: [
                        new TableCell({
                            children: [
                                createParagraph(paramLang)
                            ],
                            verticalAlign: "center",
                        }),
                        new TableCell({
                            verticalAlign: "center",
                            children: [isCheckUrl(info.value) ? createLink(info.value) : createParagraph(info.value)],
                        }),
                    ],
                }));
            });

            return childrenCell;
        },

        async prepareChildren(childrenResults, obj, resizedPhotos) {
            obj.children.push(createParagraph());


            for (const childrenResult of childrenResults.results) {
                for (const child of childrenResult.search_results) {
                    obj.children.push(createParagraphText(childrenResult.source_locales[this.selectedLocale()], '12pt'));

                    obj.children.push(
                        new Paragraph({
                            children: [
                                new TextRun({text: "- у спільноті:", size: "12pt"}),
                            ],
                            indent: {
                                firstLine: 720,
                            },
                        })
                    );

                    child.data.forEach(info => {
                        if (info.param === 'name') {
                            obj.children.push(
                                new Paragraph({
                                    children: [
                                        new TextRun({text: info.value, size: "12pt"}),
                                    ],
                                    indent: {
                                        firstLine: 720,
                                    },
                                })
                            );
                        }
                    });

                    obj.children.push(createParagraph());

                    let messageCountComment = `Загалом користувач залишив: ${childrenResult.total_count} повідомлень.`;

                    obj.children.push(createParagraphText(messageCountComment, '12pt'));

                    obj.children.push(createParagraphText());

                    for(const comment of child.comments) {
                        const table = await this.prepareComments(comment);

                        obj.children.push(createParagraphText());
                        obj.children.push(createParagraphText());

                        obj.children.push(table);

                        if (comment.children && comment.children.length) {
                            await this.prepareCircleComments(comment, obj);
                        }
                    }

                    for (const commentPagination in child.commentPaginations) {
                        for (const commentPag of child.commentPaginations[commentPagination]) {
                            const table = await this.prepareComments(commentPag);
                            obj.children.push(createParagraphText());
                            obj.children.push(createParagraphText());
                            obj.children.push(table);

                            if (commentPag.children && commentPag.children.length) {
                                if (commentPag.children && commentPag.children.length) {
                                    await this.prepareCircleComments(commentPag, obj);
                                }
                            }
                        }
                    }
                }
            }
        },

        async prepareCircleComments(comment, obj) {
            for (const commentChild of comment.children) {
                const table = await this.prepareComments(commentChild);
                obj.children.push(table);

                if (commentChild.children.length) {
                    await this.prepareCircleComments(commentChild, obj)
                }
            }
        },

        async prepareComments(comment) {
            const tableData = new ExportTableMainDto(
                comment.date,
                comment.data,
                comment.photos,
                comment.text,
                false
            );
            return await this.prepareTable(tableData);
        },

        async preparePhoto(photos) {
            let cachePhotos = [];

            if (photos.length) {
                for (let photo of photos) {
                    const imageCache = new ImageCaching();
                    const imageBase = await imageCache.loadNonComponentImage(photo.image)

                    if (imageBase) {
                        let tempImage = await this.resizeImageDoc(imageBase);
                        let width;
                        let height;
                        let coef;
                        if (tempImage.width > tempImage.height){
                            coef = 70 / tempImage.width;
                            width = tempImage.width * coef;
                            height = tempImage.height * coef;
                        } else {
                            coef = 70 / tempImage.height;
                            width = tempImage.width * coef;
                            height = tempImage.height * coef;
                        }

                        cachePhotos.push(new ImageRun({
                            data: tempImage.image,
                            transformation: {
                                width: width,
                                height: height
                            },
                        }));
                    }
                }
            }

            return new Paragraph({
                children: cachePhotos
            });
        },
    }
}
