<template>
  <div class="d-lg-flex align-items-center" v-if="meta.payload">
    <div class="text-strong" style="padding-right: 3px">{{ $t("search_list_item.options") }}: </div>

    <div class="d-lg-flex align-items-center">
      <div class="p-r-5"> {{ $t(`params.${meta.payload.requestBody.author.param}`) }}</div>
      <div>{{ meta.payload.requestBody.author.value}}</div>
    </div>

    <div v-if="meta.payload.requestBody.search_query">, "{{ meta.payload.requestBody.search_query}}"</div>

    <div v-if="meta.payload.requestBody.group?.value">
      <span class="display-none">,</span> "{{meta.payload.requestBody.group.value}}"
    </div>

    <div class="p-r-5">
                <span v-if="!meta.payload.requestBody.period_from && !meta.payload.requestBody.period_to">
                  <span class="display-none">,</span> {{ $t('comment_search.full_date')}}
                </span>
      <span v-if="meta.payload.requestBody.period_from"><span class="display-none">,</span> {{ Number(meta.payload.requestBody.period_from) | moment('DD.MM.YYYY')}} - </span>
      <span v-if="meta.payload.requestBody.period_to">{{ Number(meta.payload.requestBody.period_to) | moment('DD.MM.YYYY')}} </span>
    </div>

    <i class="feather icon-info text-primary list_history__icon-info"
       v-if="groupList.length"
       v-tippy="{ placement : 'top',  arrow: true }"
       :content="groupList"
    ></i>
  </div>
</template>

<script>
import {Result} from "@/models/result";
import {Sources} from "@/enums/sources";

export default {
  name: "CommentOptions",

  props: {
    meta: {
      type: Object,
      required: true
    },
    isViewGroup:  {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      groupList: [],
    }
  },

  mounted() {
    if (this.isViewGroup) this.getGroupList(this.meta.payload.childKey);
  },

  methods: {
    async getGroupList(keyVal) {
      let dbComments = await Result.getResultByKey(keyVal);

      this.groupList = dbComments.result.results
          .filter(item => item.source === "Telegram_scr_2023")
          .flatMap(item =>
              item.search_results.flatMap(subItem =>
                  subItem.groups.map(group =>
                      {
                        return group.data ? `<div>${group.data.find(groupItem => groupItem.param === 'name')?.value}</div>` : null
                      }
                  )
              )
          )
          .filter(Boolean).join('\n');
    }
  }
}
</script>

<style scoped>
.tippy-content {
  white-space: pre-line;
}
@media (max-width: 992px) {
  .display-none {
    display: none;
  }

  .list_history__icon-info {
    display: none;
  }
}
</style>