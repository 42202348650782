let options = {
    position: "top-center",
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
}

export function toastInfo(text, currentObj, timeout = 5000) {
    options.timeout = timeout
    currentObj.$toast.info(text, options);
}

export function toastSuccess(text, currentObj, timeout = 5000) {
    options.timeout = timeout
    currentObj.$toast.success(text, options);
}