<template>
  <li class=" active pcoded-trigger" :class="{'pcoded-hasmenu': item.hasOwnProperty('children') }"  dropdown-icon="style1" subitem-icon="style1">
    <a href="javascript:void(0)" v-if="!item.hasOwnProperty('param')" class="waves-effect waves-dark" :class="{'active-child': item.hasOwnProperty('children') && item.children.includes(currentParam)}" style="background: none;">
              <span class="pcoded-micon" style="padding-top: 4px" v-html="item.icon"></span>
      <span class="pcoded-mtext">{{ $t('side_menu.'+item.meta) }}</span>
    </a>
    <ul class="pcoded-submenu" style="background: none;" :class="{'active-child': item.hasOwnProperty('children') && item.children.includes(currentParam)}" v-if="item.hasOwnProperty('children') && item.children.length">
      <li style="cursor: pointer; background: none" :key="child" v-for="child in item.children" :class="{'active': child === currentParam}">
        <a style="padding-left: 2rem; background: none" @click.prevent="setMenuParam(child)">
              <span class="pcoded-micon" >
                <i class="feather icon-chevron-right"></i>
              </span>
          <span class="pcoded-mtext">{{ $t('side_menu.'+child) }}</span>
          <span v-if="!isUndefined(item.bages) && !isUndefined(item.bages[child])"
            class="pcoded-badge label" :class="[item.bages[child].color]">{{ item.bages[child].text }}</span>
        </a>
      </li>
    </ul>

    <a href="javascript:void(0)" v-if="item.hasOwnProperty('param')" class="waves-effect waves-dark" :class="{'active-child': item.param === currentParam }" style="background: none;" @click.prevent="setMenuParam(item.param)">
      <span class="pcoded-micon" style="padding-top: 4px" v-html="item.icon"></span>
      <span class="pcoded-mtext">{{ $t('side_menu.'+item.meta) }}</span>
    </a>


  </li>
</template>
<script>
import { isUndefined } from 'underscore';
export default {
  name: 'MenuItem',
  props: ['item', 'currentParam'],
  setup(){
    return {
      isUndefined
    }
  },
  data(){
    return {
      isMobile: false
    }
  },
  methods: {
    setMenuParam(val){
      setTimeout(() => {
        localStorage.setItem("lastParam", val);
      }, 100);
      this.$store.dispatch('mainMenuState/menuParam', val).then(()=>{
        this.$store.dispatch('contacts/forceUpdateSearchParams');
        this.$store.dispatch('full_search/clearResultFilters');
        if (val === 'Analytics'){
          this.$router.push({ name: 'MonitoringLocation' })
          this.$store.dispatch('mainMenuState/menuParam', '') 
        } else if (val === 'MonitoringOnline'){
          this.$router.push({ name: 'Monitoring' })
          this.$store.dispatch('mainMenuState/menuParam', '') 
        } else if (this.$route.name !== 'Home'){
          this.$router.push({name: 'Home'})
        }
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)){
          document.getElementsByClassName("pcoded-overlay-box")[0].click();
        }
      })
    },
  },
  mounted() {
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent);
  }
}
</script>
<style scoped>
.menuIconPd{
  padding-top: 4px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li > a {
  padding-bottom: 3px;
}
/* .pcoded[theme-layout=vertical] .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu li.active > a, */
.pcoded[theme-layout=vertical] .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu li:hover > a {
    color: #ffffff;
  }
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > .pcoded-hasmenu > .pcoded-submenu {
  padding: 0;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item > li > a {
  margin: 0;
  padding: 5px 10px 5px 20px;
}
.pcoded[theme-layout=vertical] .pcoded-navbar .pcoded-item .pcoded-hasmenu .pcoded-submenu li > a {
  padding: 5px 10px 5px 20px;
}
.pcoded .pcoded-navbar .pcoded-item > li > a > .pcoded-micon {
  padding: 0;
}
body .pcoded[theme-layout=vertical] .pcoded-navbar[active-item-theme=theme1] .pcoded-item > li.active > a,
body .pcoded[theme-layout=vertical] .pcoded-navbar[active-item-theme=theme1] .pcoded-item > li.pcoded-trigger > a {
  border-left-color: transparent;
}
body .pcoded[theme-layout=vertical] .pcoded-navbar[active-item-theme=theme1] .pcoded-item > li.active > a.active-child {
  border-left-color: #42a5f5;
}
body .pcoded[theme-layout=vertical] .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu {
  border-left-color: transparent;
}
body .pcoded[theme-layout=vertical] .pcoded-navbar[active-item-theme=theme1] .pcoded-item li .pcoded-submenu.active-child {
  border-left-color: #42a5f5;
}
</style>