<template>
  <div @scroll="handleScroll" class="main-comment__tab nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
    <div ref="listContainer">
      <div v-if="!isBlocked" v-for="(group, index) in groups" class="skeleton" :class="currentIndex === index || (typeof currentIndex === 'object' && index === 0) ? 'current-skeleton skeleton' : ''">
        <b-skeleton />
        <b-skeleton />
      </div>

      <button
          v-if="isBlocked"
          v-for="(group, index) in groups"
          :disabled="!group.count"
          :key="group.id"
          @click="getData(group.id, index)"
          class="bg-white"
          :class="{active: group.id === activeGroupId}"
          id="v-pills-home-tab"
          data-bs-toggle="pill"
          :data-bs-target="`#v-pills-home-${group.id}`"
          type="button"
          role="tab"
          aria-controls="v-pills-home"
          aria-selected="true"
      >
        <div class="d-flex align-items-center justify-content-between" :class="{'text-muted': !group.count}" v-if="group.data">
          <div>{{ group.data.find(item => item.param === 'name').value }}</div>
          <div class="m-r-20 d-flex align-items-center">
            <b-icon
                v-if="!localExistData.find(item => item.groupId === group.id && item.status) && group.count"
                icon="cloud-download-fill"
                class="m-l-10"
                aria-hidden="true"
            />
            <i class="feather icon-info text-primary m-l-10"
               v-if="group.count && !localExistData.find(item => item.groupId === group.id && item.status)"
               v-tippy="{ placement : 'top',  arrow: true }"
               :content="$t('comment_search.hint.price_request', { credit: search_rates?.defaults?.offline_result_price })"
            />

            <b-icon
                v-if="group.count && localExistData.find(item => item.groupId === group.id && item.status)"
                variant="success"
                class="m-l-10"
                font-scale="1.1"
                icon="cloud-check-fill"
            />

            <i class="feather icon-info text-primary m-l-10"
               v-if="group.count && localExistData.find(item => item.groupId === group.id && item.status)"
               v-tippy="{ placement : 'top',  arrow: true }"
               :content="$t('comment_search.hint.received_data')"
            />

            <b-icon
                v-if="!group.count"
                icon="cloud-slash"
                class="m-l-10"
                aria-hidden="true"
            />
            <i class="feather icon-info text-primary m-l-10"
               v-if="!group.count"
               v-tippy="{ placement : 'top',  arrow: true }"
               :content="$t('comment_search.hint.empty_group')"
            />

            <b-spinner
                v-if="!isBlocked && activeGroupId === group.id"
                type="border"
                small
            />
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="text-muted">{{ $t('comment_search.content_type') }}:</div>
          <div class="badge bg-secondary m-l-5 m-r-10 rounded">group</div>
          <div class="d-flex align-items-center">
            <div class="text-muted">{{ $t('comment_search.count') }}:</div>
            <div
                class="badge bg-primary m-l-5 rounded"
                :class="activeGroupId === group.id ? 'bg-primary' : 'bg-secondary'"
            >
              {{ group.count || 0}}
            </div>
          </div>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'GroupTabs',

  props: {
    groups: {
      type: Array,
      required: true,
    },
    isBlocked: {
      type: Boolean,
      default: false,
    },
    localExistData: {
      type: Array,
      required: true,
    },
    activeGroupId: {
      type: String|null,
      required: true,
    },
  },

  data() {
    return {
      currentIndex: null,
      visibleItems: [],
      itemsPerLoad: 10,
      loadedCount: 0,
    }
  },

  computed: {
    ...mapGetters('search_rates', ['search_rates', 'servicesList']),
  },

  mounted() {
    this.$store.dispatch('search_rates/getSearchRates');
    this.loadMore();
  },

  methods: {
    loadMore() {
      if (this.groups.length < 10) this.visibleItems = this.groups;

      if (this.loadedCount >= this.groups.length) return;

      if (this.groups.length > 10) {
        const nextItems = this.groups.slice(
            this.loadedCount,
            this.loadedCount + this.itemsPerLoad
        );

        this.visibleItems.push(...nextItems);
        this.loadedCount += this.itemsPerLoad;
      }
    },

    handleScroll() {
      const container = this.$refs.listContainer;

      if (container.scrollTop + container.clientHeight >= container.scrollHeight - 10) {
        this.loadMore();
      }
    },

    getData(id, index) {
      if (this.activeGroupId === id) return;

      this.currentIndex = index;
      this.$emit('get-data', id);
    },
  }
}
</script>

<style scoped>
.main-comment__tab button{
  width: 305px;
  padding: 10px;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0;
}

.main-comment__tab button.active {
  border-left: 4px solid #4099ff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  right: -2px;
  box-shadow: -4px 0 5px 0 rgba(43,43,43,.1),0 11px 6px -7px rgba(43,43,43,.1);
}

.main-comment__tab >>> .b-skeleton {
  width: 230px;
  height: 15px;
  border: 0;
  transition: border-left 0.3s ease;
  margin-left: 5px;
}

.main-comment__tab .skeleton {
  width: 305px;
  padding: 14px 14px 14px 5px;
  background: #fff;
}

.main-comment__tab .current-skeleton {
  border-left: 4px solid #4099ff;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
  right: -2px;
  box-shadow: -4px 0 5px 0 rgba(43,43,43,.1),0 11px 6px -7px rgba(43,43,43,.1);
}

.main-comment__tab {
  padding-left: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  direction: rtl;
  text-align: left;
  width: 448px;
  max-height: 530px;
}

.main-comment__tab > div {
  direction: ltr;
  padding-top: 5px;
}

.main-comment__tab::-webkit-scrollbar {
  width: 8px;
}

.main-comment__tab::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.main-comment__tab::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.main-comment__tab::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main-comment__tab {
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
}

@media (max-width: 992px) {
  .main-comment__tab {
    padding: 0;
    width: 100%;
  }

  .main-comment__tab button {
    width: 100%;
    right: 0!important;
    border-left: 4px solid #ffffff;
    border-right: 4px solid #ffffff;
  }

  .main-comment__tab .current-skeleton {
    border-left: 4px solid #4099ff;
    border-right: 4px solid #4099ff;
    border-radius: 5px;
    position: relative;
    width: 100%;
    box-shadow: -4px 0 5px 0 rgba(43,43,43,.1),0 11px 6px -7px rgba(43,43,43,.1);
  }

  .main-comment__tab button.active {
    border-right: 4px solid #4099ff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

@keyframes borderAnimation {
  0% {
    border-left: 1px solid #c8c8c8;
  }
  25% {
    border-left: 2px solid #4099ff;
  }
  75% {
    border-left: 3px solid #4099ff;
  }
  100% {
    border-left: 4px solid #4099ff;
  }
}
</style>