export function buildTreeComments() {
    const map = {};

    this.structureComments = JSON.parse(JSON.stringify(this.telegramGroupComments))

    this.structureComments.forEach(telegramGroupComment => {
        telegramGroupComment.search_results.forEach(item => {
            item.children = [];
            map[item.id] = item;
        });

        const result = [];

        const calculateLevel = (item, currentLevel = 0) => {
            item.level = currentLevel;

            item.author= telegramGroupComment.relative_results.authors.find(author => author.id === item.author_id);

            item.children.forEach(child => {
                calculateLevel(child, currentLevel + 1);
            });
        };

        telegramGroupComment.search_results.forEach(item => {
            if (item.parent_id && map[item.parent_id] && !map[item.reply_to_id]) {
                map[item.parent_id].children.push(item);
            } else if (item.reply_to_id && map[item.reply_to_id]) {
                map[item.reply_to_id].children.push(item);
            } else {
                result.push(item);
            }
        });

        result.forEach(rootComment => {
            calculateLevel(rootComment);
        });

        telegramGroupComment.search_results = result;
    });
}