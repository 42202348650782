import i18n from "@/i18n";

export function setPluralize(key, count, locale) {
    let form = "many";

    if (locale === "ru" || locale === "ua") {
        if (count % 10 === 1 && count % 100 !== 11) form = "one";
        else if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) form = "few";
    } else if (locale === "en") {
        form = count === 1 ? "one" : "many";
    }

    return i18n.t(`plurals.${key}.${form}`);
}