import {BorderStyle, ExternalHyperlink, Paragraph, TextRun} from "docx";

export function createLink(link, text = null) {
    return new Paragraph({
        children: [
            new ExternalHyperlink({
                children: [
                    new TextRun({
                        text: text === null ? link : text,
                        style: "Hyperlink",
                    }),
                ],
                link: link,
            }),
        ],
    });
}

export function createParagraph(text = '') {
    return new Paragraph({ text: text, bold: true });
}

export function createParagraphText(text, size = "7pt") {
    return new Paragraph({
        children: [
            new TextRun({
                text: text,
                size: size,
            })
        ],
    })
}

export function optionBorderNone() {
    return {
        top: { style: BorderStyle.NONE, size: 0 },
        left: { style: BorderStyle.NONE, size: 0 },
        bottom: { style: BorderStyle.NONE, size: 0 },
        right: { style: BorderStyle.NONE, size: 0 },
    }
}

export function optionBorderStandard() {
    return {
        top: {
            style: BorderStyle.SINGLE,
            size: 6,
            color: "000000",
        },
        bottom: {
            style: BorderStyle.SINGLE,
            size: 6,
            color: "000000",
        },
        left: {
            style: BorderStyle.SINGLE,
            size: 6,
            color: "000000",
        },
        right: {
            style: BorderStyle.SINGLE,
            size: 6,
            color: "000000",
        },
    };
}

export function isCheckUrl(str) {
    const pattern = /^(https?:\/\/[^\s$.?#].[^\s]*)$/i;
    return pattern.test(str);
}