<template>
  <div class="w-100" style="border-bottom: 1px solid #ededed;">
    <div class="list-group-item info-block-contact" v-for="countLabel in Object.keys(relations)" :key="countLabel">
      <div class="d-flex align-items-center justify-content-between">
        <div>{{ $t(`relations.${countLabel}`) }}: {{relations[countLabel]}}</div>
        <search-comment
            v-if="countLabel !== ContentType.POSTS"
            :searchParam="searchParam"
            :relation-id="relationId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import SearchComment from "@/components/search/comments/search_comment.vue";
import {ContentType} from "@/enums/content_type";

export default {
  name: "CommunityBlock",

  components: {
    SearchComment,
  },

  props: {
    relations: {
      type: Object,
      required: true,
    },
    relationId: {
      type: String,
      required: true,
    },
    searchParam: {
      type: Object,
      required: true,
    }
  },

  computed: {
    ContentType() {
      return ContentType
    }
  }
}
</script>

<style scoped>

</style>