<template>
  <div class="accordion accordion-group-data group-info mb-3">
    <div class="accordion-item">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
              aria-expanded="false"
              data-bs-target="#collapse-group_info" aria-controls="collapse-group_info">
        <div class="text-truncate d-flex justify-content-between w-100">
          {{ $t('comment_search.group_details') }}
        </div>
      </button>

      <div id="collapse-group_info" class="accordion-collapse collapse"
           aria-labelledby="heading-group_info">
        <div class="accordion-body p-t-0">
          <div v-if="isBlockGroupName">
            <div class="text-strong">{{ $t('comment_search.previous_group_names') }}:</div>
            <span class="m-l-10 d-block" v-for="data in group.data">
                <span v-if="data.param === 'name'">{{ data.value }}</span>
              </span>
          </div>

          <div v-for="data in group.data">
              <span v-if="data.param !== 'name'">
                <span v-if="data.param === 'social_url'">
                  <span class="text-strong">{{ $t(`comment_search.link`) }}:</span>
                  <span>
                    <a :href="data.value" target="_blank" class="f-15">
                      {{ data.value }} <b-icon icon="box-arrow-in-up-right"></b-icon>
                    </a>
                  </span>
                </span>
                <span v-else>
                  <span class="text-strong">{{ $t(`params.${data.param}`) }}:</span>
                  <span> {{ data.value }}</span>
                </span>
              </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupInfo",

  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isBlockGroupName() {
      return this.group.data.filter(group => group.param === 'name').length > 1;
    }
  }
}
</script>

<style scoped>
.group-info .accordion-item, .group-info button {
  background-color: #d1ecf1;
  border: 0;
}

.f-15 {
  font-size: 15px;
}
</style>