<template>
  <tippy
      interactive
      :animate-fill="false"
      placement="top"
      distant="7"
      theme="light"
      animation="shift-toward"
      trigger="mouseenter"
      offset="interactive:true"
      arrow>
    <template v-slot:trigger>
      <div class="rating" :class="{ 'blue-rating': rating >= 8, 'green-rating': rating < 8 && rating > 4, 'yellow-rating': rating <= 4 }" style="cursor:pointer;">
        <div class="f-w-700">{{$t('base_source_date.rating')}}: </div> <div style=" margin-left: 4px">{{rating}}</div>
        <i class="feather icon-info" style="display: flex; margin-left: 4px;justify-content: center; align-items: center"></i>
      </div>
    </template>
    <div class="alignLeft">
      <div><span style="font-weight: 700">{{$t('base_source_date.data_reliability')}}: </span> {{ratingDetails.data_reliability}}/5 </div>
      <div><span style="font-weight: 700">{{$t('base_source_date.source_reliability')}}: </span> {{ratingDetails.source_reliability}}/5</div>
    </div>
  </tippy>
</template>

<script>
export default {
  name: "sourceRating",
  props: ['rating', 'ratingDetails']
}
</script>

<style scoped>
.blue-rating{
  background-color: #4099ff;
}
.green-rating{
  background-color: #00bcd4;
}
.yellow-rating{
  background-color: #ff9a0a;
}
.rating{
  color: white;
  padding: 2px 6px;
  border-radius: 3px;
  display: flex;
  width: fit-content;
}
</style>