<template>
  <ul class="tree">
    <li>
      <details :open="isOpen">
        <summary>{{ groupName }}</summary>
        <ul>
          <li v-for="item in data">
            <span v-if="item.param === 'social_url'">
                  <span class="text-strong m-r-10">{{ $t(`comment_search.link`) }}:</span>
                  <span>
                    <a :href="item.value" target="_blank">
                      {{ item.value }} <b-icon icon="box-arrow-in-up-right"></b-icon>
                    </a>
                  </span>
            </span>
            <span v-else>
                  <span v-if="item.param === 'name'" class="text-strong m-r-10">{{ $t(`comment_search.param.${item.param}`) }}:</span>
                  <span v-else class="text-strong m-r-10">{{ $t(`params.${item.param}`) }}:</span>
                  <span>{{ item.value }}</span>
            </span>
          </li>
        </ul>
      </details>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'CommunicationRelation',

  props: {
    data: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    isOpenAll: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    isBlockGroupName() {
      return this.data.filter(group => group.param === 'name').length > 1;
    },

    groupName() {
      return this.data.find(Boolean).value;
    },

    isOpen() {
      return this.total < 12 || this.isOpenAll;
    }
  }
}
</script>

<style scoped>
.group-info .accordion-item, .group-info button {
  background-color: #ebebeb;
  border: 0;
}

.accordion-button {
  box-shadow: none;
}



.tree {
  --spacing: 1.5rem;
  --radius: 10px;
  --main_radius: 6px;
}

.tree li {
  display: block;
  position: relative;
  padding-left: calc(2 * var(--spacing) - var(--radius) - 2px);
}

.tree ul {
  margin-left: calc(var(--radius) - var(--spacing));
  padding-left: 0;
}

.tree ul li {
  border-left: 2px solid #ddd;
}

.tree ul li:last-child {
  border-color: transparent;
}

.tree ul li::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / -2);
  left: -2px;
  width: calc(var(--spacing) + 2px);
  height: calc(var(--spacing) + 1px);
  border: solid #ddd;
  border-width: 0 0 2px 2px;
}

.tree summary {
  display: block;
  cursor: pointer;
  padding-left: 5px;
}

.tree summary::marker,
.tree summary::-webkit-details-marker {
  display: none;
}

.tree summary:focus {
  outline: none;
}

.tree summary:focus-visible {
  outline: 1px dotted #000;
}

.tree summary::before {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / 2 - var(--radius));
  left: calc(var(--spacing) - var(--radius) - 1px);
  width: calc(2 * var(--radius));
  height: calc(2 * var(--radius));
  border-radius: 50%;
  background: #ddd;
}

.tree li::after {
  content: '';
  display: block;
  position: absolute;
  top: calc(var(--spacing) / 2 - var(--main_radius));
  left: calc(var(--spacing) - var(--main_radius) - 1px);
  width: calc(2 * var(--main_radius));
  height: calc(2 * var(--main_radius));
  border-radius: 50%;
  background: #ddd;
}

.tree summary::before {
  z-index: 1;
  background: #4099ff url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIj8+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNDAiIGhlaWdodD0iMjAiPgogIDxnIGZpbGw9IiNmZmYiPgogICAgPHBhdGggZD0ibTUgOWg0di00aDJ2NGg0djJoLTR2NGgtMnYtNGgtNHoiLz4KICAgIDxwYXRoIGQ9Im0yNSA5aDEwdjJoLTEweiIvPgogIDwvZz4KPC9zdmc+Cg==") 0 0;
}

.tree details[open] > summary::before {
  background-position: calc(-2 * var(--radius)) 0;
}

.tree ul li a {
  font-size: .9375rem;
}
</style>