import axios from "@/configs/axios";

const state = {
    relations: [],
    count: 0,
    total: 0,
}
const getters = {
    relations: state => state.relations,
    count: state => state.count,
    total: state => state.total,
}

const actions = {
    async getRelations({commit, dispatch}, data) {
        commit("clearRelations");
        const query = new URLSearchParams(data.body);

        let result = await axios({url: `entity/${data.relationId}/relations?${query.toString()}`,  method: 'GET'});

        if (result.status === 200) {
            commit('setRelations', result.data.data)
            commit('setCount', result.data.count)
            commit('setTotal', result.data.total)
        }
    },

    async getPaginateRelations({commit, dispatch}, data) {
        const query = new URLSearchParams(data.body);

        let result = await axios({url: `entity/${data.relationId}/relations?${query.toString()}`,  method: 'GET'});

        if (result.status === 200) {
            commit('setPaginateRelations', result.data.data)
            commit('setCount', result.data.count)
            commit('setTotal', result.data.total)
        }
    },
}

const mutations = {
    setRelations(state, relations) {
        state.relations = relations;
    },

    setPaginateRelations(state, relations) {
        state.relations = state.relations.concat(relations);
    },

    clearRelations(state) {
        state.relations = [];
    },

    setCount(state, count) {
        state.count = count;
    },

    setTotal(state, total) {
        state.total = total;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}