<template>
  <div class="d-flex flex-row-reverse">
    <button
        v-if="search_rates?.defaults"
        :content="$t('comment_search.hint.detail_preview')"
        v-tippy="{ placement : 'top',  arrow: true }"
        class="btn btn-primary m-r-10 m-b-5 m-t-5"
        @click="$bvModal.show(`telegram-group-${relationId}`)"
    >
      <span v-if="search_rates?.defaults">
        {{ costComment }}
      </span>
    </button>

    <b-skeleton v-else class="m-r-10 m-b-10 m-t-5" height="35px" width="148px"></b-skeleton>

    <b-modal
        size="lg" scrollable
        :id="`telegram-group-${relationId}`"
        centered
        hide-footer
        @hidden="closeModal"
        @show="openModal"
    >
      <template #modal-title>
        <span v-if="Object.keys(modalTitle).length">
          {{ modalTitle[locale()]?.toLowerCase().split(/\s+/).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') }}:
          {{ searchParam?.value }}
        </span>
        <span v-else><b-skeleton width="200px" height="20px" /></span>
      </template>

      <div class="main-tabs">
        <b-tabs content-class="m-t-20">
          <b-tab :title="$t('comment_search.group')" active @click="selectTab(false)">
            <b-container>
              <b-row class="align-items-center justify-content-center">
                <b-col xl="3" md="3" sm="7">{{ $t(`comment_search.showPeriod`) }}:</b-col>
                <b-col>
                  <ui-calendar
                      class="m-t-10 m-b-15"
                      :is-disabled="allPeriod"
                      :placeholder="$t(`comment_search.start_date`)"
                      :period-selected-to="selectedPeriodTo"
                      :value="selectedPeriodFrom"
                      @setDate="selectedPeriodFrom = $event"
                  />
                </b-col>
                <b-col>
                  <ui-calendar
                      class="m-t-10 m-b-15"
                      :is-disabled="allPeriod"
                      :placeholder="$t(`comment_search.end_date`)"
                      :period-selected-from="selectedPeriodFrom"
                      :value="selectedPeriodTo"
                      @setDate="selectedPeriodTo = $event"
                  />
                </b-col>
                <b-col cols="12" md="3" class="border-checkbox-section">
                  <div class="border-checkbox-group border-checkbox-group-primary">
                    <input
                        type="checkbox"
                        class="border-checkbox"
                        id="all_period"
                        v-model="allPeriod"
                    >
                    <label
                        for="all_period"
                        class="border-checkbox-label"
                    >
                      {{ $t('comment_search.full_date') }}
                    </label>
                  </div>
                </b-col>
                <div class="d-flex justify-content-center m-t-20">
                  <b-col cols="12" md="3" xl="3"
                        v-if="isFormReady"
                        v-tippy="{ placement: 'top', arrow: true, content: $t(`comment_search.hint.select_date`) }"
                  >
                    <b-button
                        disabled
                        class="w-100"
                        variant="primary"
                    >
                        {{ $t(`comment_search.search`) }}
                    </b-button>
                  </b-col>

                  <b-col cols="12" md="3" xl="3" v-else>
                    <button
                        class="btn btn-primary w-100"
                        @click="getCountComments"
                    >
                      {{ $t(`comment_search.search`) }}
                    </button>
                  </b-col>
                </div>
              </b-row>
            </b-container>
          </b-tab>

          <b-tab :title="$t('comment_search.quickSearch')" @click="selectTab(true)">
            <b-container>
              <b-row class="align-items-center m-b-20">
                <b-col xl="3" md="3" sm="7">{{ $t(`comment_search.searchQuery`) }}</b-col>
                <b-col>
                  <b-form-input v-model="searchQuery" />
                </b-col>
              </b-row>

              <b-row  class="align-items-center m-b-20">
                <b-col xl="3" md="3" sm="7">{{ $t(`comment_search.groupName`) }}</b-col>
                <b-col>
                  <b-form-input v-model="groupName" />
                </b-col>
              </b-row>
            </b-container>

            <b-container>
              <b-row class="align-items-center">
                <b-col xl="3" md="3" sm="7">{{ $t(`comment_search.showPeriod`) }}:</b-col>
                <b-col>
                  <ui-calendar
                      :is-disabled="allPeriod"
                      :placeholder="$t(`comment_search.start_date`)"
                      :period-selected-to="selectedPeriodTo"
                      :value="selectedPeriodFrom"
                      @setDate="selectedPeriodFrom = $event"
                  />
                </b-col>
                <b-col>
                  <ui-calendar
                      :is-disabled="allPeriod"
                      :placeholder="$t(`comment_search.end_date`)"
                      :period-selected-from="selectedPeriodFrom"
                      :value="selectedPeriodTo"
                      @setDate="selectedPeriodTo = $event"
                  />
                </b-col>
                <b-col cols="12" md="3" class="border-checkbox-section mt-xl-0 m-t-20">
                  <div class="border-checkbox-group border-checkbox-group-primary">
                    <input
                        type="checkbox"
                        class="border-checkbox"
                        id="all_period"
                        v-model="allPeriod"
                    >
                    <label
                        for="all_period"
                        class="border-checkbox-label"
                    >
                      {{ $t('comment_search.full_date') }}
                    </label>
                  </div>
                </b-col>
                <div class="d-flex justify-content-center m-t-20">
                  <b-col cols="12" md="3" xl="3"
                        v-if="isFormReady"
                        v-tippy="{ placement: 'top', arrow: true, content: $t(`comment_search.quick_search_error_empty_fields`) }"
                  >
                    <b-button
                        disabled
                        class="w-100"
                        variant="primary"
                    >
                        {{ $t(`comment_search.search`) }}
                    </b-button>
                  </b-col>

                  <b-col cols="12" md="3" xl="3" v-else>
                    <button
                        class="btn btn-primary w-100"
                        @click="getCountComments"
                    >
                      {{ $t(`comment_search.search`) }}
                    </button>
                  </b-col>
                </div>
              </b-row>
            </b-container>
          </b-tab>
        </b-tabs>
      </div>

      <preview-skeleton v-if="!isLoaded && isViewSkeleton" />

      <div v-if="isLoaded && !commentGroups.length" class="text-center m-t-20">
        {{ $t('errors.not_found') }}
      </div>

      <div
          v-if="isLoaded && commentGroups && !isModelExistRecord"
          class="m-t-20"
      >
        <div v-for="commentGroup in commentGroups">
          <div v-if="isLoaded && !commentGroup.search_results.length" class="d-flex justify-content-center m-t-20 bg-light">
            {{ $t('result.nothing_found') }}
          </div>

          <div v-for="search_result in commentGroup.search_results" :key="search_result.period_from" class="preview_search__main">
            <div v-if="search_result.count" class="preview_search__head_counts">{{ $t(`comment_search.countComments`) }}: {{ search_result.count }}</div>
            <div v-if="search_result.count" class="d-flex   preview_search__head_period">
              <span class="p-r-10">{{ $t(`comment_search.periodFrom`) }}:</span>
              <div>{{ moment(search_result.period_from, 'X').format('DD MMM YYYY') }} - {{ moment(search_result.period_to, 'X').format('DD MMM YYYY') }}</div>
            </div>

            <table class="table table-hover table-responsive-md table-responsive-custom">
              <thead>
                <th>{{ $t('ui.group') }}</th>
                <th>{{ $t('comment_search.periodFrom') }}</th>
                <th>{{ $t('comment_search.comment') }}</th>
              </thead>
              <tbody>
              <tr v-for="group in search_result.groups" :key="group.id" class="table-line">
                <td :data-label="$t('ui.group')">
                  <div class="d-flex">
                    <div class="col-12 col-lg-3 border-checkbox-section">
                      <div class="border-checkbox-group border-checkbox-group-primary">
                        <input
                            :disabled="!group.count"
                            type="checkbox"
                            class="border-checkbox"
                            :id="'checkbox-' + group.id" v-model="groups"
                            :key="group.id" :value="group"
                        >
                        <label
                            :for="'checkbox-' + group.id"
                            class="border-checkbox-label"
                        >
                          <span v-if="group.data">{{ group.data.find(item => item.param === 'name').value }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </td>
                <td :data-label="$t('comment_search.periodFrom')">
                <span v-if="group.count">
                  {{ moment(group.period_from, 'X').format('DD MMMM YYYY') }} -
                  {{ moment(group.period_to, 'X').format('DD MMMM YYYY') }}
                </span>
                </td>
                <td :data-label="$t('comment_search.comment')">{{ group.count || '0'}}</td>
              </tr>
              </tbody>
            </table>

            <div v-if="search_result.count" class="preview_search__counts">
              {{ $t(`comment_search.countSelectedGroup`) }}: {{ groups.length }},
              {{ $t('comment_search.money_written', {credit: search_rates?.defaults?.offline_result_price} )}}
            </div>
          </div>
        </div>

        <b-container>
          <b-row>
            <div class="d-flex justify-content-center m-t-40">
              <b-col cols="12" md="3" xl="3" v-if="!groups.length" v-tippy="{ placement: 'top', arrow: true, content: $t('comment_search.hint.show_comment_preview') }">
                <b-button
                    disabled
                    class="w-100"
                    variant="primary"
                >
                  {{ $t('search.open') }}
                </b-button>
              </b-col>

              <b-col cols="12" md="3" xl="3" v-if="groups.length">
                <b-button
                    class="w-100"
                    variant="primary"
                    @click="$bvModal.show(`bv-modal-${relationId}`)"
                >
                  {{ $t('search.open') }}
                </b-button>
              </b-col>
            </div>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <comment-main-search :data="searchData" :groups="groups" :modal-id="relationId" :modal-title="modalTitle[locale()]"/>

    <b-modal
        v-model="isModelExistRecord"
        centered
        :title="$t('search.query_exist')"
        hide-footer
        has-modal-card
        trap-focus :destroy-on-hide="false" aria-role="dialog"
        aria-modal
        @hide="localComments"
    >
      <template>
        <div class="modal-card" style="width: auto">
          <footer class="d-grid">
            <div class="d-flex">
              <span class="p-r-10">{{ $t(`comment_search.periodFrom`) }}:</span>
              <span v-if="typeof historyParams.period_from !== 'undefined' && typeof historyParams.period_to !== 'undefined'">
                <span v-if="typeof historyParams.period_from !== 'undefined'">
                  {{ moment(historyParams.period_from, 'X').format('DD MMM YYYY') }} -
                </span>
                <span v-else>sxsx</span>
                <span v-if="typeof historyParams.period_to !== 'undefined'">
                  {{ moment(historyParams.period_to, 'X').format('DD MMM YYYY') }}
                </span>
                <span v-else>{{ $t('comment_search.full_date')}}</span>
              </span>
              <span v-else>{{ $t('comment_search.full_date')}}</span>
            </div>

            <b-button variant="primary" class="mt-3" @click="remoteGroups()">
              {{ $t('search.update') }}
              <span v-if="search_rates?.defaults && search_rates?.defaults?.offline_result_price > 0">({{ search_rates?.defaults?.offline_result_price}} {{ $t('comment_search.credit_short') }})</span>
            </b-button>
            <b-button variant="secondary" class="mt-2" @click="localComments()">{{ $t('search.open') }}</b-button>
          </footer>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import moment from "moment";
import DatePicker from 'vue2-datepicker';
import CommentMainSearch from "@/components/search/comments/commentMainSearch.vue";
import {Result} from "@/models/result";
import telegramMonitor from "@/mixins/telegramMonitor";
import PreviewSkeleton from "@/components/search/comments/components/preview_skeleton.vue";
import UiCalendar from "@/components/ui/form/Calendar.vue";

export default {
  name: "SearchComment",

  mixins: [telegramMonitor],

  setup() {
    return {
      moment
    }
  },

  components: {
    UiCalendar,
    CommentMainSearch,
    DatePicker,
    PreviewSkeleton,
  },

  props: ['searchParam', 'relationId'],

  data() {
    return {
      firstRecordId: '',
      isModelExistRecord: false,
      isLoaded: false,
      isViewSkeleton: false,
      selectedPeriodFrom: null,
      selectedPeriodTo: null,
      groups: [],
      searchQuery: '',
      groupName: '',
      parentKey: '',
      modalTitle: '',
      searchData: {},
      isFullParams: false,
      allPeriod: false,
      commentGroups: [],
    }
  },

  computed: {
    ...mapGetters('search_comment', ['telegramComment', 'historyParams']),
    ...mapGetters('search_rates', ['search_rates', 'servicesList']),

    isFormReady() {
      return this.isFullParams
          ? !((this.allPeriod || Boolean(this.selectedPeriodFrom) || Boolean(this.selectedPeriodTo)) && Boolean(this.searchQuery.length || this.groupName.length))
          : !(this.allPeriod || Boolean(this.selectedPeriodFrom) || Boolean(this.selectedPeriodTo))
    },

    costComment() {
      const credit =
          this.search_rates?.defaults?.offline_result_price === 0
              ? ''
              : `(${this.search_rates?.defaults?.offline_result_price}${this.$t('comment_search.credit_short')})`;

      return this.$t('comment_search.detail_with_credit', { credit });
    }
  },

  mounted() {
    this.$store.dispatch('search_rates/getSearchRates');
  },

  methods: {
    ...mapMutations('search_comment', ['setClearTelegramComments']),

    selectTab(isTab) {
      this.isFullParams = isTab;
      this.clearDataForm();
    },

    clearDataForm() {
      this.selectedPeriodFrom = null;
      this.selectedPeriodTo = null;
      this.searchQuery = '';
      this.groupName = '';
    },

    async firstOpen() {
      moment.locale(this.locale());

      this.selectedPeriodFrom = moment().subtract(1, 'year').startOf('day');
      this.selectedPeriodTo = moment().startOf('day');
      this.prepareBodyRequest();

      let firstData = {... this.searchData};
      firstData.main = true;

      this.parentKey = this.generatePreviewDbKeyWithParent(firstData, 'preview');

      await this.$store.dispatch('search_comment/getLocalPreviewTelegramGroupComments', this.parentKey);

      if (this.telegramComment.length) {
        this.isModelExistRecord = true;
        this.clearDataForm();
      }

      if (!this.telegramComment.length) {
        await this.remoteGroups();
      }

      this.groupComments();

      await this.$store.dispatch('search_rates/getSearchRates');
    },

    async getCountComments() {
      this.groups = [];
      this.prepareBodyRequest();

      this.parentKey = this.generatePreviewDbKeyWithParent(this.searchData, 'preview');
      const response = await this.$store.dispatch('search_comment/getLocalPreviewTelegramGroupComments', this.parentKey);

      if (!Object.keys(response).length) {
        this.clearData();

        await this.remoteGroups();
        this.groupComments();
        return;
      }

      if (Object.keys(response).length) {
        this.isModelExistRecord = true;
      }

      this.groupComments();
    },


    groupComments() {
      this.commentGroups = [... this.telegramComment];

      this.commentGroups.forEach((source) => {
        this.modalTitle = source.source_locales;

        if (source.search_results === null) {
          source.search_results = [];
          source.search_results.push(source.relative_results);
        } else {
          source.relative_results.groups.forEach((group) => {
            let findGroup = source.search_results.find(Boolean).groups.find(item => item.id === group.id);
            if (typeof findGroup === 'undefined') source.search_results.find(Boolean).groups.push(group);
            if (typeof findGroup !== 'undefined') {
              Object.assign(findGroup, group);
            }
          });
        }
      });
    },

    async remoteGroups() {
      this.isModelExistRecord = false;
      await Result.deleteResult(this.parentKey);

      this.isLoaded = false;
      this.isViewSkeleton = true;

      await this.$store.dispatch('search_comment/getTelegramComments', this.searchData);

      this.isViewSkeleton = false;

      if (this.telegramComment.length) {
        for (const group of this.telegramComment) {
          let payload = {
            cache_search_enabled: true,
            online_search_enabled: false,
            value: this.searchData.author.value,
            sources: group.source,
            childKey: this.parentKey,
            requestBody: this.searchData,
          };

          await this.$store.dispatch('contacts/cacheWithResultsChild', {
            data: [group],
            type: 'telegram_monitor_preview',
            parentKey: this.$store.getters["contacts/meta"].key,
            payload
          });
        }
      }

      this.isLoaded = true;
    },

    prepareBodyRequest() {
      const data = {
        'author': this.searchParam,
      }

      if (this.isFullParams) {
        if (this.groupName) data['group'] = {"param": "name", "value": this.groupName};
        if (this.searchQuery) data['search_query'] = this.searchQuery;
      }

      if (!this.allPeriod) {
        if (this.selectedPeriodFrom) {
          data['period_from'] = moment(this.selectedPeriodFrom).format("X");
        }

        if (this.selectedPeriodTo) {
          data['period_to'] = moment(this.selectedPeriodTo).add(23, 'hour').add(59, 'minute').add(59, 'second').format("X");
        }
      }

      this.searchData = data;
    },

    localComments() {
      this.isModelExistRecord = false;
      this.isViewSkeleton = false;
      this.isLoaded = true;
    },

    clearData() {
      this.setClearTelegramComments()
      this.isLoaded = false;
    },

    closeModal() {
      this.clearData();
      Object.assign(this.$data, this.$options.data.call(this));
    },

    openModal() {
      this.firstOpen()
    },

    locale() {
      return this.$i18n.locale === 'ua' ? 'uk' : this.$i18n.locale
    },
  }
}
</script>

<style scoped>
.main-tabs >>> .comment-tab {
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #37474f;
  border-radius: 0;
  color: #37474f;
  width: 200px;
  text-align: center;
}

.main-tabs >>> .comment-tab.active, .main-tabs >>> .comment-tab.active:hover {
  color: #0275d8;
  text-align: center;
  border-bottom: 2px solid #0275d8;
}

.preview_search__main {
  padding-left: 12px;
  padding-right: 12px;
}

@media (max-width: 992px) {
  body .border-checkbox-section .border-checkbox-group .border-checkbox-label {
    margin-right: 0;
  }

  .table-responsive-custom {
    margin-top: 10px;
  }

  .table-responsive-custom thead{
    display: none;
  }

  tr.table-line {
    display: flex;
    border-bottom: 1px solid #ccc;
    width: 100%;
  }

  .table-responsive-custom td::before {
    content: attr(data-label);
    font-weight: bold;
    width: 30%;
    display: flex;
    align-items: center;
  }

  .table-responsive-custom tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    padding-bottom: 15px;
  }

  .table-responsive-custom td {
    border-bottom: 1px solid #ddd;
    display: flex;
    font-size: .8em;
    text-align: right;
  }

  .table-responsive-custom td:last-child {
    border-bottom: 0;
  }

  .table tr.table-line td {
    display: flex;
    padding-top: 5px !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    border: 0;
    width: 100%;
  }
}

@media (max-width: 530px) {
  .mb-md-5 {
    //margin-bottom: 5px;
  }
}
</style>