import {Result} from "@/models/result";

export async function nextPage(currentPage, dataRequest) {
    if (currentPage === this.currentPage) return;
    this.currentPage = currentPage;

    let offset = calculateOffset(currentPage);

    if (offset === 0) {
        await this.getComments(dataRequest['group_id'][0]);
        return;
    }

    let childKey = this.generatePreviewDbKeyWithParent(dataRequest, 'full', this.meta.parent);
    let dbResponse = await Result.getResultByKey(childKey);
    let dbResponsePaginate = await Result.getResultByKey(`social_content_relation_${dbResponse.id}_page_${currentPage}`);

    for (const comment of this.telegramGroupComments) {
        if (typeof dbResponsePaginate === 'undefined') {
            this.isLoaded = false;

            let data = {
                body: {limit: 20, offset: offset},
                query: {request_id: comment.request_id, source_id: comment.source_id}
            };

            let response = await this.$store.dispatch('search_comment/getTelegramGroupCommentsPaginate', data);

            this.$store.commit('search_comment/setTelegramGroupCommentsPaginate', response);

            this.isLoaded = true;

            let payload = {
                cache_search_enabled: true,
                online_search_enabled: false,
                value: this.meta.payload.requestBody.author.value,
                sources: comment.source,
                childKey: `social_content_relation_${dbResponse.id}_page_${currentPage}`,
                countResult: comment.total_count,
                requestBody: this.meta.payload.requestBody,
            }

            comment['param'] = this.meta.payload.requestBody.author.param;

            await this.$store.dispatch('contacts/cacheWithResultsChild', {
                data: this.telegramGroupComments,
                type: 'telegram_monitor',
                parentKey: this.meta.parent,
                payload
            });
        } else {
            this.$store.commit('search_comment/setLocalTelegramComments', dbResponsePaginate.result.results);
        }
    }
}

function calculateOffset(currentPage) {
    let offset = 0;

    if (currentPage === 1) return offset;
    if (currentPage === 2) return offset = 10;
    return (currentPage * 20) - 30;
}