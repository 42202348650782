<template>
  <div
      :class="
      comment.level === 0
      ? 'comment_parent'
      : 'comment_child'"
  >
    <div class="d-flex justify-content-between">
      <div class="d-flex items-center w-100" style="padding: 20px 0;">
        <div v-if="comment.level > 0" style="margin-right: 20px;">
          <div v-if="comment.level === 1" class="icon_repeat"></div>
          <div v-if="comment.level > 1" class="icon_repeat_double"></div>
        </div>
        <div class="w-100">
          <div :class="comment.level < 9 ? 'comment__user_info' : 'comment__user_info_many'">
            <div :class="comment.level < 9 ? 'comment__user_info_main' : 'comment__user_info_main_many'">
              <div
                  v-for="(data, index) in comment.data.data"
                  :key="index"
                  style="padding-right: 20px"
              >
                <span v-if="data.param === 'about'">
                  <span
                      class="tooltip icon_user_del left-5 position-r top-2"
                      style="display: block; position: relative; top: -1px; left: 4px;"
                  >
                    <span class="tooltiptext">{{ $t('comment_search.hint.account_deleted') }}</span>
                  </span>
                </span>
                <span v-else>
                   <span v-if="data.param === 'social_url'" class="font-bold">{{ $t(`comment_search.link`) }}: </span>
                <span v-else class="font-bold">{{ $t(`params.${data.param}`) }}: </span>

                <a :href="data.value" v-if="data.param === 'social_url'" class="text-black" target="_blank">
                  {{ formatUrl(data.value) }}
                </a>
                <span v-else-if="data.param === 'username' || data.param === 'telegram_id'">
                  <span v-if="data.param === 'username'">
                    <a :href="`https://t.me/${data.value}`" class="text-black" target="_blank">{{ data.value }}</a>
                  </span>
                  <span v-else>{{ data.value }}</span>
                  <span class="tooltip icon_clipboard left-5 position-r top-2" :data-text="data.value" onclick="copyToClipboard(event)">
                    <span class="tooltiptext">{{ $t('comment_search.hint.copy_to_buffer') }}</span>
                  </span>
                </span>
                <span v-else>{{ data.value }}</span>
                </span>
              </div>
            </div>

            <div>
              <span style="font-weight: bold;">{{ $t('comment_search.date') }}: </span>
              <span class="p-l-5">{{ moment.unix(comment.date).format('DD.MM.YYYY HH:mm') }}</span>
            </div>
          </div>
          <div style="display: flex;padding-top: 8px; align-items: flex-start;">
            <div><strong>{{ $t('comment_search.contentType.comment') }}: </strong></div>
            <div style="padding-left: 5px; display: flex; align-items: flex-start;">
              <div>
                <span v-if="comment.source_link" class="tooltip icon_link position-r top-3" style="left: -1px;" :data-text="comment.source_link" onclick="copyToClipboard(event)">
                  <span class="tooltiptext">{{ $t('comment_search.hint.copy_to_buffer') }}</span>
                </span>
                "{{ comment.text }}"
                <span class="tooltip icon_clipboard position-r top-3" onclick="copyToClipboard(event)">
                  <span class="tooltiptext">{{ $t('comment_search.hint.copy_to_buffer') }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="authorIds.find(item => item === comment.author_id) ? 'current_user' : 'other_user'"></div>
    </div>

    <CommentTree
        v-for="child in comment.children"
        :key="child.id"
        :comment="child"
        :author-ids="authorIds"
    />
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "CommentTree",
  computed: {
    moment() {
      return moment
    }
  },

  props: {
    comment: {
      type: Object,
      required: true,
    },
    authorIds: {
      type: Array,
      required: true,
    }
  },

  methods: {
    formatUrl(url) {
      return url.replace(/^https?:\/\//, "");
    }
  }
}
</script>

<style scoped>

</style>