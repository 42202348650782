<template>
  <div class="tab-content main-comment__content m-t-5 p-l-30 p-r-20" :class="{'d-flex': isLoaded}" id="v-pills-tabContent">
    <div class="tab-pane fade active show" :id="`#v-pills-home-${activeGroupId}`" role="tabpanel"
         aria-labelledby="v-pills-home-tab">
      <div v-if="isEmptyGroups" class="not-comment">
        {{ $t('comment_search.empty_groups') }}
      </div>

      <div v-if="!structureComments.length && isLoaded && !isEmptyGroups" class="not-comment">
        {{ $t('comment_search.info_select_group') }}
      </div>

      <group-info v-if="structureComments.length && isLoaded" :group="group" />

      <div class="accordion accordion-flush d-flex flex-wrap align-content-between" id="accordionFlushExample"
           v-for="telegramGroupComment in structureComments">
        <div class="w-100">
          <div class="accordion-item" v-for="comment in telegramGroupComment.search_results">

            <b-skeleton v-if="!isLoaded" class="m-t-10" height="3rem" width="100%"/>

            <h2 class="accordion-header" :id="'heading-' + comment.id" v-if="structureComments.length && isLoaded">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                      aria-expanded="false"
                      :data-bs-target="'#collapse-' + comment.id" :aria-controls="'collapse-' + comment.id">
                <div class="text-truncate d-flex align-items-center m-r-15">
                  <b-icon v-if="comment.children.length" class="limitIcon field m-r-10" icon="chat-right-text-fill"
                          size="is-medium"/>
                  <b-icon v-if="!comment.children.length" class="limitIcon field m-r-10" icon="card-text"
                          size="is-medium"/>

                  <span class="d-lg-flex" v-html="randomCommentCurrentAuthor(comment)"></span>
                </div>
              </button>
            </h2>
            <div :id="'collapse-' + comment.id" class="accordion-collapse collapse"
                 :aria-labelledby="'heading-' + comment.id" v-if="structureComments.length && isLoaded">
              <div class="p-10 accordion-body">
                <CommentTree :comment="comment"/>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center w-100 align-items-center m-t-30">
          <comment-search-pagination
              :loadedStatus="isLoaded"
              :per-page="20"
              :total-records="telegramGroupComment.matches_count"
              @page-changed="nextPage($event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CommentSearchPagination from "@/components/search/CommentSearchPagination.vue";
import CommentTree from "@/components/search/comments/CommentTree.vue";
import moment from "moment";
import GroupInfo from "@/components/search/comments/components/GroupInfo.vue";

export default {
  name: "CommentList",

  components: {GroupInfo, CommentSearchPagination, CommentTree},

  props: {
    structureComments: {
      type: Array,
      required: true,
    },
    isLoaded: {
      type: Boolean,
      default: false,
    },
    authorId: {
      type: Array | null,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    activeGroupId: {
      type: String,
      default: "",
    },
    isEmptyGroups: {
      type: Boolean,
      default: false,
    }
  },

  methods: {
    nextPage(currentPage) {
      this.$emit("next-page", currentPage);
    },

    randomCommentCurrentAuthor(comment) {
      let currentAuthorComment = this.findUserComment(comment, this.authorId);
      let dot = '';

      if (currentAuthorComment) {
        if (currentAuthorComment?.text.length > 40) dot = '...';
        return `${currentAuthorComment?.text.slice(0, 40)} ${dot} <div class="m-l-10 fst-italic text-muted ">${moment.unix(currentAuthorComment.date).format('DD.MM.YYYY')} ${moment.unix(currentAuthorComment.date).format('HH:mm')}</div>`;
      }

      if (comment?.text.length > 40) dot = '...';
      return `${comment?.text.slice(0, 40)} ${dot} <div class="m-l-10 fst-italic text-muted">${moment.unix(comment.date).format('DD.MM.YYYY')} ${moment.unix(comment.date).format('HH:mm')}</div>`;

    },

    findUserComment(comment, authorIds) {
      let authorId = authorIds.find(authorId => authorId === comment.author_id);

      if (typeof authorId !== "undefined") {
        return {"text": comment.text, "date": comment.date};
      }

      for (let i = 0; i < comment.children.length; i++) {
        const item = comment.children[i];
        let authorId = authorIds.find(authorId => authorId === item.author_id);
        if (typeof authorId !== "undefined") {
          return {"text": item.text, "date": item.date};
        }

        this.findUserComment(item, authorIds);
      }
    },
  }
}
</script>

<style scoped>
.group-info accordion-button:focus {
  outline: none !important;
  box-shadow: 0 0 0 !important;
}

.main-comment__content {
  min-height: 400px;
  width: 100%;
  border: 0;
  padding: 30px;
  box-shadow: 0 0 5px 0 rgba(43,43,43,.1),0 11px 6px -7px rgba(43,43,43,.1);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.main-comment__content .tab-pane {
  width: 100%;
}

.main-comment__content >>> .accordion-button:focus{
  outline: none !important;
  box-shadow: none;
  color: #212529!important;
}

.main-comment__content >>> .accordion-button:not(.collapsed) {
  color: #212529;
}

.main-comment__content >>> .accordion-button:not(.collapsed):after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 01.708 0L8 10.293l5.646-5.647a.5.5 0 01.708.708l-6 6a.5.5 0 01-.708 0l-6-6a.5.5 0 010-.708z'/%3E%3C/svg%3E");
}

.not-comment {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff9c;
  font-size: 20px;
  color: #535353;
}

@media (max-width: 992px) {
  .main-comment__content {
    padding: 0!important;
    margin-top: 30px;
  }

  .main-comment__content >>> .accordion-button{
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 10px;
  }
}
</style>